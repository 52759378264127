import React from "react"
import styled from "styled-components"
import {
  message,
  Spin,
  Radio,
  Pagination,
  DatePicker,
  Space,
  Form,
  Input,
  Col,
  Row,
  Switch,
  Select,
  Button,
  Upload,
} from "antd"

import {
    UploadOutlined
  } from "@ant-design/icons"

import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import api from "../../util/api"
import config from "../../util/config"

const Box = styled.div`
  background: #f4f5f7;
  min-height: 100vh;
  font-size: 17px;
  .title {
    margin: 22px;
  }
  `
  const TitleCss = styled.div`
  padding-left: 8px;
  font-size: 17px;
  font-weight: bold;
  position: relative;
  color: #000000;
  &:before {
    content: "";
    width: 2px;
    height: 19px;
    background: #1769c5;
    border-radius: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

  const Title = ({ title, className, showQry, click }) => (
    <TitleCss className={className}>
      {title}
  
      
    </TitleCss>
  )

class Pdf extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
       loading:false,
       pdfurl:config.server_url+'pdfMk',
       result:''
    }
  }
  componentDidMount() {
    
  }
  beforeUpload(file){
    console.log('file.type==',file)
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      message.error(`${file.name} 不是个pdf文件`);
    }
    return isPdf || Upload.LIST_IGNORE;
  }

  onChange(info) {
    this.setState({
        loading:true
    })
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
        this.setState({
            loading:false
        })
        console.log('info==',info)
        this.setState({
            result:info.file.response.path
        })
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
        this.setState({
            loading:false
        })
      message.error(`${info.file.name} file upload failed.`);
    }
  }
  
  openPdf() {
    let pdf =
      "http://www.huijuzx.com/" + this.state.result
    console.log("pdf==", pdf)
    window.open(pdf, "_blank")
  }
  

  render() {
    const {
      loading,
      pdfurl,
      result,
    } = this.state
    return (
      <Box>
        <Title className="title" title='pdf加密及水印'></Title>
        <Spin spinning={loading}>
        <Row>
      <Col span={6}>
      <Upload
        action={pdfurl}
        name="pdfFile"
        onChange={this.onChange.bind(this)}
        beforeUpload={this.beforeUpload.bind(this)}
        className="title"
      >
        <Button icon={<UploadOutlined />}>上传文件</Button>
      </Upload>
      </Col>
      <Col span={12}>
        <div className="title">转换结果：
        <a href="#!" onClick={() => this.openPdf()}>
                    {result}
                  </a>
        </div>
        </Col>
        </Row>
        </Spin>
      </Box>
    )
  }
}
export default Pdf
