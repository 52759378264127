import React from 'react'
import './index.scss'
import map from '../../assets/imgs/contackUs/map.png'
import star from '../../assets/imgs/contackUs/star.png'

class ContactUs extends React.PureComponent {
  constructor() {
    super()
  }

  render() {
    return (
      <div className="contactUs">
        <h2>联系我们</h2>
        <div className="contactUsContent">
          <div className="left">
            <h4>总部</h4>
            <p>公司地址: 上海浦东新区郭守敬路498号张江浦东软件园区14幢</p>
            <p>联系人: 何小姐</p>
            <p>QQ: 2962165512</p>
            <p>微信: 13761387372</p>
            <p>联系电话: 18917455235</p>
            <p>联系邮箱: market1@longboinfo.com</p>
          </div>
          <div className="right">
            <img className="map" src={map} alt="" />
            <div className="starBox">
              <img className="star" src={star} />
              <div class="circle1"></div>
              <div class="circle2"></div>
              <div class="circle3"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactUs
