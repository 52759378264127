import React from 'react'
import './index.scss'
import pic from '../../assets/imgs/aboutUs/pic.png'

class AboutUs extends React.PureComponent {
  constructor() {
    super()
  }

  render() {
    return (
      <div className="aboutUs">
        <div className="aboutUsTitle">关于我们</div>
        <div className="textContent">
          <p className="companyIntro">公司简介</p>
          <p>
            龙柏信息是一家从事移动互联网金融资讯服务商，专注于证券信息、资讯、情报、数据、分析工具的智能化大数据研究，长期
          </p>
          <p>
            服务于国家部委、银行、保险、证券、基金、私募、上市公司和民营企业，{' '}
          </p>
          <p>官网：www.huijuzx.com.cn 开利投顾智库</p>
          <p>
            微信号：kailicaijing
            ；内参，研报，智库，数据定制，涨停资讯，事件主题，一屏掌握。
          </p>
          <img className="pic" src={pic} alt="" />
        </div>
      </div>
    )
  }
}

export default AboutUs
