import React from "react"
import moment from "moment"

// import "./style/index.scss"
import styled from "styled-components"
import {
  Spin,
  Input,
  Select,
  Table,
  Pagination,
  Tabs,
  DatePicker,
  message,
  AutoComplete,
  Popover,
  Button,
  Tooltip,
  Form,
  Row,
  Col,
  Tree,
} from "antd"

import "@toast-ui/editor/dist/toastui-editor.css"
import "@toast-ui/editor/dist/toastui-editor-viewer.css"
import { Viewer } from "@toast-ui/react-editor"

import { DataNode, TreeProps } from "antd/es/tree"
import {
  DownOutlined,
  UpOutlined,
  ReloadOutlined,
  LeftCircleFilled,
} from "@ant-design/icons"

import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"

import api from "../../util/api"
import config from "../../util/config"

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom"

const { Column } = Table
const { RangePicker } = DatePicker

const TuBox = styled.div`
  font-size: 17px;

  .tusize {
    font-size: 17px;
  }

  .innerhtml {
    padding: 1px 0;
    font-size: 15px;
    color: rgb(51, 51, 51);
    line-height: 36px;
  }
  .pic_tu {
    width: 1000px;
    height: auto;
    margin-top: 30px;
    margin-bottom: 80px;
  }
`

const ColumnTitle = (title, qryStr) => (
  <span>
    {title}
    {/* {qryStr ? (
      <Tooltip title="刷新">
        <ReloadOutlined
          style={{ marginLeft: "1.25em" }}
          onClick={() => this.reload.bind(this)}
        />
      </Tooltip>
    ) : null} */}
  </span>
)

class Data extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      idx: 1,
      text: "",
      menuData: [],
      treeData: [],
      menuTitle: "",
      tabList: [
        {
          label: "慧选Tushare Pro",
          value: 1,
        },
        {
          label: "数据接口",
          value: 2,
        },
         {
           label: "数据工具",
           value: 3,
         },
      ],
      content: "",
    }
  }

  componentDidMount() {
    if (!api.checkLogin()) {
      message.error("请登陆")
      this.props.history.push("/")
    } else {
      this.getData()
    }
  }
  getData() {
    console.log("getdata..")
    api.getCorpDataMenu().then((res) => {
      this.setState(
        {
          menuData: res,
        },
        () => {
          this.getTreeData()
        }
      )
    })
  }

  changeTab(value) {
    console.log("qryType_group==", this.state.qryType_group)
    this.setState(
      {
        idx: value,
        // text: this.state.tabList.find((item) => item.value == value).label,
      },
      () => {
        if (value==3){
            window.open('https://tushare.pro/webclient','_blank')
        }else
           this.getTreeData()
      }
    )
  }

  onSelect(selectedKeys, info) {
    if (info.selected) {
      this.getDataContent(selectedKeys[0])
      this.setState({
        menuTitle: info.selectedNodes[0].title,
      })
    }
  }
  getTreeData() {
    let menuData = this.state.menuData
    let menuType = this.state.idx
    let treeData = []
    let expandedKeys = []
    let selectMenuId = 0
    console.log("this.state.idx===", this.state.idx)
    if (menuData && menuData.Table && menuData.Table.length > 0) {
      menuData.Table.map((item) => {
        if (item.menu_type == menuType) {
          selectMenuId = selectMenuId > 0 ? selectMenuId : item.id
          if (item.menu_code && item.menu_code.length == 2) {
            treeData.push({
              key: item.id,
              title: item.menu_name,
              children: this.getChildren(item.menu_code, menuData.Table),
            })
            expandedKeys.push(item.id.toString())
          }
        }
      })
      this.setState({
        treeData: treeData,
        expandedKeys,
      })
    }
    if (selectMenuId > 0) {
      this.getDataContent(selectMenuId)
    }
  }
  getChildren(code, table) {
    let menuData = []
    let finder = table.filter((item) => {
      return (
        item.menu_code.length == code.length + 2 &&
        item.menu_code.substring(0, code.length) == code
      )
    })
    if (finder.length > 0) {
      finder.map((item) => {
        menuData.push({
          key: item.id,
          title: item.menu_name,
          children: this.getChildren(item.menu_code, table),
        })
      })
    }
    return menuData
  }
  getDataContent(menuId) {
    this.setState({
      loading: true,
    })
    api.getCorpDataContent(menuId).then((res) => {
      this.setState({
        loading: false,
      })
      let content = ""
      let menuTitle = ""
      if (res && res.Table && res.Table.length > 0) {
        content = res.Table[0].content
        menuTitle = res.Table[0].menu_title
      }
      this.refs.myViewer.getInstance().setMarkdown(content)
      if (menuTitle != "") {
        this.setState({
          menuTitle: menuTitle,
        })
      }
    })
  }

  render() {
    const {
      loading,
      idx,
      tabList,
      content,
      treeData,
      menuTitle,
      expandedKeys,
    } = this.state

    return (
      <TuBox>
        {/* 顶部tab */}
        <div className="top">
          <div className="contarin">
            <ul>
              {tabList.map((t, index) => {
                return (
                  <li
                    key={index}
                    className={idx === t.value ? "active" : ""}
                    onClick={() => this.changeTab(t.value)}
                  >
                    {t.label}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        {/* 内容部分  */}
        <Spin spinning={loading}>
          {idx==3 ? (
          null
          ) :(
          <div className="content" style={{ background: "#fff" }}>
            <div className="content_bg">
              <div className="content_box">
                <Row gutter={20}>
                  <Col span={6}>
                    <Tree
                      showLine
                      switcherIcon={<DownOutlined />}
                      onSelect={this.onSelect.bind(this)}
                      treeData={treeData}
                    />
                  </Col>
                  <Col span={18}>
                    <div className="commonTitle">
                      <div className="leftName">{menuTitle}</div>
                    </div>

                    {/* <div dangerouslySetInnerHTML={{__html:this.state.content}}></div> */}

                    <Viewer value={content} ref="myViewer" />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          )}
        </Spin>
      </TuBox>
    )
  }
}

export default Data
