import React from "react"
import { NavLink, Link } from "react-router-dom"
import styled from "styled-components"

import {
  message,
  Spin,
  Radio,
  Pagination,
  DatePicker,
  Space,
  Form,
  Input,
  Col,
  Row,
  Switch,
  Select,
  Button,
  Descriptions,
  Menu,
  Table,
} from "antd"
import {
  CaretUpOutlined,
  CaretDownOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import api from "../../../util/api"
import moment from "moment"

const { Column } = Table

class Contact extends React.PureComponent {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    console.log("getContact .....")
    api.getContact().then((res) => {
      console.log("getContact res==", res)
      this.setState({
        list: res && res.Table ? res.Table : [],
        loading: false,
      })
    })
  }

  render() {
    const { list } = this.props
    return (
      <Table
        dataSource={list}
        rowKey={(record) => record.ID}
        style={{ margin: "40px" }}
      >
        <Column
          title="姓名"
          dataIndex="NAME"
          render={(NAME) => {
            return <div className="combinationName fontSize16">{NAME}</div>
          }}
        />
        <Column
          title="QQ"
          dataIndex="QQ"
          render={(QQ) => {
            return <div className="combinationName fontSize16">{QQ}</div>
          }}
        />
        <Column
          title="TEL"
          dataIndex="TEL"
          render={(TEL) => {
            return <div className="combinationName fontSize16">{TEL}</div>
          }}
        />
      </Table>
    )
  }
}
export default Contact
