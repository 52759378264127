import React from "react"
import { NavLink, Link } from "react-router-dom"
import styled from "styled-components"
import {
  message,
  Spin,
  Radio,
  Pagination,
  DatePicker,
  Space,
  Form,
  Input,
  Col,
  Row,
  Switch,
  Select,
  Button,
  Descriptions,
  Table,
  Image,
  Menu,
  Modal,
  List,
  Skeleton,
  Avatar,
  Comment,
  Divider,
  Tooltip,
} from "antd"
import {
  EditTwoTone,
  SearchOutlined,
  SolutionOutlined,
  CreditCardOutlined,
  SolutionTwoTone,
  StarTwoTone,
  IeOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import api from "../../util/api"
import { thisExpression, throwStatement } from "@babel/types"
import moment from "moment"
const { Column } = Table
const { confirm } = Modal

const IndexCss = styled.div`
  width: 860px;
  margin: 0 auto;
  background: url(/static/media/bg.b511bb82.png) no-repeat;
  background-size: 100%;
  min-height: 50vh;
  padding: 20px;
  .content_bg_ask {
    background: linear-gradient(to bottom, #e5f1ff 0%, transparent 20%);
  }
  .content_box_ask {
    padding: 20px 10px;
    background: #fff;
  }
  .btn {
    margin-left: 40px;
  }
  .divh {
    padding-top: 10px;
  }
`
const TitleCss = styled.div`
  padding-left: 22px;
  padding-top: 7px;
  font-size: 17px;
  font-weight: bold;
  position: relative;
  height: 40px;
  color: #000000;
  background: #fff;
  &:before {
    content: "";
    width: 2px;
    height: 15px;
    background: #1769c5;
    border-radius: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`
const TitleQryBtnCss = styled.div`
  float: right;
  margin-right: 20px;
`

const Title = ({ title, className, showQry, click }) => (
  <TitleCss className={className}>
    &nbsp;&nbsp;<span>{title}</span>
    {/* <TitleQryBtnCss>
      {showQry ? (
        <CaretUpOutlined style={{ color: "#3c81ce" }} onClick={click} />
      ) : (
        <CaretDownOutlined style={{ color: "#3c81ce" }} onClick={click} />
      )}
    </TitleQryBtnCss> */}
  </TitleCss>
)

class AskDetails1 extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      guid: "",
      ftype: "1",
      loading: false,
    }
  }

  componentDidMount() {
    let guid = api.decode(this.props.match.params.id)
    let ftype = api.decode(this.props.match.params.type)
    this.setState({
      loading: false,
      ftype: ftype,
    })
  }

  render() {
    const ExampleComment = ({ children }) => (
      <Comment
        actions={[<span key="comment-nested-reply-to"></span>]}
        author={<a>张天师</a>}
        avatar={<Avatar src="https://www.huijuzx.com/images/Vip.png" alt="" />}
        content={<p>请问vip续费需要怎么办理?</p>}
        datetime={
          <Tooltip title={moment().format("YYYY-MM-DD HH:mm:ss")}>
            <span>
              {moment("2022-03-22 14:21").format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </Tooltip>
        }
      >
        <Divider />
        {children}
      </Comment>
    )
    const ExampleComment1 = ({ children }) => (
      <Comment
        actions={[<span key="comment-nested-reply-to"></span>]}
        author={<a>李四</a>}
        avatar={<Avatar src="https://www.huijuzx.com/images/Vip.png" alt="" />}
        content={<p>请问请问请问请问请问请问请问请问请问请问?</p>}
        datetime={
          <Tooltip title={moment().format("YYYY-MM-DD HH:mm:ss")}>
            <span>
              {moment("2022-03-23 10:01").format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </Tooltip>
        }
      >
        <Divider />
        {children}
      </Comment>
    )
    const Reply = ({}) => (
      <Comment
        actions={[<span key="comment-nested-reply-to"></span>]}
        author={<a>龙柏科技</a>}
        avatar={
          <Avatar
            src="https://www.huijuzx.com/images/Services.png"
            alt="龙柏"
          />
        }
        content={<p>请打开知识星球app或小程序，搜索慧选牛牛</p>}
        datetime={
          <Tooltip title={moment().format("YYYY-MM-DD HH:mm:ss")}>
            <span>
              {moment("2022-03-22 20:00").format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </Tooltip>
        }
      ></Comment>
    )

    const Business = ({ children }) => (
      <Comment
        actions={[<span key="comment-nested-reply-to"></span>]}
        author={<a>董先生</a>}
        avatar={
          <Avatar src="https://www.huijuzx.com/images/Business.png" alt="" />
        }
        content={<p>研究报告栏目合作</p>}
        datetime={
          <Tooltip title={moment().format("YYYY-MM-DD HH:mm:ss")}>
            <span>
              {moment("2022-03-20 11:11").format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </Tooltip>
        }
      >
        <Divider />
        {children}
      </Comment>
    )
    const Reply1 = ({}) => (
      <Comment
        actions={[<span key="comment-nested-reply-to"></span>]}
        author={<a>龙柏科技</a>}
        avatar={
          <Avatar
            src="https://www.huijuzx.com/images/Services.png"
            alt="龙柏"
          />
        }
        content={<p>收到，感谢支持！！！</p>}
        datetime={
          <Tooltip title={moment().format("YYYY-MM-DD HH:mm:ss")}>
            <span>
              {moment("2022-03-21 10:10").format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </Tooltip>
        }
      ></Comment>
    )

    const { loading, ftype } = this.state

    return (
      <IndexCss>
        <Spin spinning={loading}>
          <div className="content_ask">
            <div className="content_bg_ask">
              <div className="content_box_ask">
                <div className="divh"></div>
                {ftype == 1 ? (
                  <div>
                    <ExampleComment>
                      <Reply></Reply>
                    </ExampleComment>
                    <Divider />
                    <ExampleComment1></ExampleComment1>

                    <Divider />
                  </div>
                ) : (
                  <div>
                    <Business>
                      <Reply1></Reply1>
                    </Business>
                    <Divider />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Spin>
      </IndexCss>
    )
  }
}

export default AskDetails1
