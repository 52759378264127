import React from "react"
import { NavLink, Link } from "react-router-dom"
import styled from "styled-components"
import weapp from "../../../assets/imgs/mine/klcj_weapp.jpg"

import {
  message,
  Spin,
  Radio,
  Pagination,
  DatePicker,
  Space,
  Form,
  Input,
  Col,
  Row,
  Switch,
  Select,
  Button,
  Descriptions,
  Menu,
  Table,
} from "antd"
import {
  CaretUpOutlined,
  CaretDownOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import api from "../../../util/api"
import moment from "moment"

const { Column } = Table

const BuyCss = styled.div`
  .wximg { 
    width:160px;
    height:160px;
    margin-top:20px;
  }
  .wxfont{
    margin-top:40px;
    font-size:14px;
  }
`

class MyBuy extends React.PureComponent {
  constructor(props) {
    super(props)
  }
  componentDidMount() {}

  render() {
    const { list } = this.props
    return (
      <BuyCss>

<Row>
      <Col span={16}> <Table
        dataSource={list}
        rowKey={(record) => record.GUID}
        style={{ margin: "40px" }}
      >
        <Column
          title="VIP购买"
          dataIndex="REMARK"
          render={(REMARK) => {
            return <div className="combinationName fontSize16">{REMARK}</div>
          }}
        />
      </Table></Col>
      <Col span={8}>
        <p className="wxfont">
      微信扫码，登录小程序支付 ：
      </p>
      <p></p>
      <p>
      <img src={weapp} alt="" className="wximg"/></p>
      </Col>
    </Row>
         
     
      </BuyCss>
    )
  }
}
export default MyBuy
