import React from "react"
import { NavLink, Link } from "react-router-dom"
import styled from "styled-components"
import {
  message,
  Spin,
  Radio,
  Pagination,
  DatePicker,
  Space,
  Form,
  Input,
  Col,
  Row,
  Switch,
  Select,
  Button,
  Descriptions,
  Menu,
  Table,
  Tooltip,
} from "antd"
import {
  CopyOutlined,
  EyeOutlined,
} from "@ant-design/icons"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import api from "../../../util/api"
import { thisExpression, throwStatement } from "@babel/types"
import moment from "moment"

const IndexCss = styled.div`
  .form{
    margin-top:40px;
  }

  .tokenleft{
    margin-left:350px;width: 700px;
  }
`
const { Column } = Table

class ApplyToken extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tabValue: 1,
      loading: false,
      isSecret:true,
    }
    
    console.log("myInfo===", props.myInfo)
    console.log("applyHis===", props.applyHis)
  }
  componentDidMount() {}

  onFormFinish(values) {
    console.log("values===", values)
    this.setState({
      loading: true,
    })
    api.applyToken(values.txtCorp,values.txtPosition,values.txtMobile,values.txtEmail).then((res) => {
      console.log("res=", res)
      this.setState({
        loading: false,
      })
      if (res && res.valid) {
        message.success("提交成功")
        window.location.reload()
      } else {
        let errors = res && res.errors || "对不起，提交失败! "
        console.log('errors==',errors)
        message.error(errors)
      }
    })
  }

  setSecret(){
    console.log('aaaa')
    this.setState({
      isSecret:!this.state.isSecret
    })
    console.log('this.state.isSecret===',this.state.isSecret)
  }

  render() {
    const { loading,isSecret } = this.state
    const { myInfo,applyHis } = this.props

    const validatorPwd = ({ getFieldValue }) => ({
      validator(rule, value) {
        if (getFieldValue("txtPassword") == value) return Promise.resolve()
        else return Promise.reject("两次密码输入不一致")
      },
    })

    

    return (
      <IndexCss>

      <Spin spinning={loading} >

      
      {myInfo.TOKEN ||  applyHis.length>0 ? (
      <div className="form tokenleft">
      <Input.Group compact>
      <Input
        style={{ width: 'calc(100% - 200px)' }}
        defaultValue={isSecret ? '**********************************' : myInfo.TOKEN}
      />
      <Tooltip title="查看">
        <Button icon={<EyeOutlined />} onClick={this.setSecret.bind(this)} />
      </Tooltip>
      <Tooltip title="复制">
        <Button icon={<CopyOutlined />} />
      </Tooltip>
      </Input.Group>
    </div>

     ) : null }
     
      
      {applyHis.length>0? (
        <Table
        dataSource={applyHis}
        rowKey={(record) => record.ID}
        style={{ margin: "40px" }}
      >
        <Column
          title="申请时间"
          dataIndex="APPLYTIME"
          render={(APPLYTIME) => {
            return <div className="combinationName fontSize16">{APPLYTIME}</div>
          }}
        />
      </Table>
      ) : (
      <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            autoComplete="off"
            className="form"
            initialValues={{
              txtMobile: myInfo?.CORP,
              txtPosition: myInfo?.POSITION,
              txtMobile: myInfo?.MOBILE,
              txtEmail: myInfo?.EMAIL,
          }}
            onFinish={this.onFormFinish.bind(this)}
          >
            <Form.Item
              label="机构"
              name="txtCorp"
              rules={[{ required: true, message: "请填写所属机构!" }]}
            >
              <Input />
            </Form.Item>

            
            <Form.Item
              label="职位"
              name="txtPosition"
              rules={[{ required: true, message: "请填写职位!" }]}
            >
              <Input />
            </Form.Item>

            
            <Form.Item
              label="手机"
              name="txtMobile"
              value={"myInfo.MOBILE"}
              rules={[{ required: true, message: "请填写手机!" }]}
            >
              <Input />
            </Form.Item>

            
            <Form.Item
              label="邮箱"
              name="txtEmail"
              rules={[{ required: true, message: "请填写邮箱!" }]}
            >
              <Input />
            </Form.Item>


            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交申请
              </Button>
            </Form.Item>
          </Form>
          
         )}

          
        </Spin>
        </IndexCss>
    )
  }
}
export default ApplyToken
