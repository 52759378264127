import Index from "../views/index"
import Roport from "../views/report"
import RoportDetail from "../views/report/detail"
import Meeting from "../views/meeting"
import MeetingDetail from "../views/meeting/detail"
import GoldStock from "../views/goldStock"
import ContactUs from "../views/contactUs/index"
import AboutUs from "../views/aboutUs/index"
import GoldStockBase from "../views/goldStock/goldStockBase"
import GoldStockDetails from "../views/goldStock/detail"
import Events from "../views/events/index"
import Regist from "../views/regist/index"
import Mine from "../views/mine/index"
import Forgot from "../views/forgot/index"
import ResetPwd from "../views/forgot/resetPwd"
import Data from "../views/data/index"
import Group from "../views/group/index"
import GroupDetails from "../views/group/detail"
import ViewDetails from "../views/group/viewDetail"
import GroupAsk from "../views/group/askDetail"
import Pdf from "../views/pdf/index"
import TpsNews from "../views/tpsNews"
import TpsNewsDetails from "../views/tpsNews/detail"
import BBs from "../views/bbs" 
import PaidInfo from "../views/paidInfo"
import PaidInfoDetail from "../views/paidInfo/detail"

const route = [
  {
    name: "首页",
    path: "/",
    exact: true,
    component: Index,
  },
  {
    name: "机构研报",
    path: "/report",
    exact: true,
    component: Roport,
  },
  {
    name: "机构研报详情",
    path: "/report/detail/:id",
    exact: true,
    component: RoportDetail,
  },

  {
    name: "机构会议",
    path: "/meeting",
    exact: true,
    component: Meeting,
  },
  {
    name: "机构会议详情",
    path: "/meeting/detail/:id",
    exact: true,
    component: MeetingDetail,
  },

  {
    name: "机构金股",
    path: "/goldStock",
    exact: true,
    component: GoldStock,
  },
  {
    name: "机构金股详情",
    path: "/goldStock/details/:id",
    exact: true,
    component: GoldStockDetails,
  },

  {
    name: "注册",
    path: "/regist",
    exact: true,
    component: Regist,
  },

  {
    name: "我的信息",
    path: "/mine",
    exact: true,
    component: Mine,
  },

  {
    name: "机构事件",
    path: "/events",
    exact: true,
    component: Events,
  },

  {
    name: "机构数据",
    path: "/data",
    exact: true,
    component: Data,
  },

  {
    name: "机构社群",
    path: "/group",
    exact: true,
    component: Group,
  },
  {
    name: "机构社群详情",
    path: "/group/details/:id/:award",
    exact: true,
    component: GroupDetails,
  },
  {
    name: "机构观点详情",
    path: "/group/viewDetails/:id",
    exact: true,
    component: ViewDetails,
  },
  {
    name: "机构VIP通道",
    path: "/group/askDetails/:id/:type",
    exact: true,
    component: GroupAsk,
  },

  {
    name: "台湾电子",
    path: "/tpsNews",
    exact: true,
    component: TpsNews,
  },
  {
    name: "台湾电子详情",
    path: "/tpsNews/detail/:id",
    exact: true,
    component: TpsNewsDetails,
  },
  {
    name: "知识付费",
    path: "/paidInfo",
    exact: true,
    component: PaidInfo,
  },
  {
    name: "知识付费详情",
    path: "/paidInfo/detail/:id",
    exact: true,
    component: PaidInfoDetail,
  },
  
  {
    name: "联系我们",
    path: "/contactUs",
    exact: true,
    component: ContactUs,
  },
  {
    name: "关于我们",
    path: "/aboutUs",
    exact: true,
    component: AboutUs,
  },
  {
    name: "忘记密码",
    path: "/forgot",
    exact: true,
    component: Forgot,
  },
  {
    name: "密码重置",
    path: "/resetPwd/:id",
    exact: true,
    component: ResetPwd,
  },
  {
    name: "pdf加密",
    path: "/pdf",
    exact: true,
    component: Pdf,
  },
  
  {
    name: "论坛",
    path: "/bbs",
    exact: true,
    component: BBs,
  },
]

export default route
