export const barOption = {
  xAxis: {
    max: "dataMax",
    show: false,
  },
  yAxis: {
    type: "category",
    data: ["展示功能", "B展示功能", "C展示功能", "展示功能D", "展示功能E"],
    inverse: true,
    animationDuration: 300,
    animationDurationUpdate: 300,
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      textStyle: {
        color: "#333", //更改坐标轴文字颜色
        fontSize: 16, //更改坐标轴文字大小
      },
    },
  },
  series: [
    {
      realtimeSort: true,
      name: "X",
      type: "bar",
      barWidth: 20,
      data: [122, 22, 52, 6, 2],
      label: {
        show: true,
        position: "right",
        valueAnimation: true,
        color: "#fd0000",
        fontWeight: "bold",
        fontSize: 16,
        formatter: "{c}%" + "↑",
      },
      itemStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#F1F8FF", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#49A3E3", // 100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
    },
  ],
  legend: {
    show: false,
  },
  grid: {
    left: "100px",
    right: "100px",
    top: 0,
  },
  animationDuration: 0,
  animationDurationUpdate: 3000,
  animationEasing: "linear",
  animationEasingUpdate: "linear",
}

export const pieOption = {
  tooltip: {
    trigger: "item",
    formatter: "{a} <br/>{b} : {c} ({d}%)",
  },
  legend: {
    type: "plain",
    // orient: 'vertical',
    top: "30%",
    left: "50%",
    // right: 0,
    // top: 20,
    // bottom: 20,
    data: ["sss", "22", "11", "222", "1111", "233", "2222"],
    icon: "circle",
    itemGap: 22,
    textStyle: {
      fontSize: 16,
      color: "#333",
    },
  },
  series: [
    {
      name: "",
      type: "pie",
      radius: "90%",
      center: ["20%", "30%"],
      label: {
        normal: {
          show: false,
        },
      },
      labelLine: {
        normal: {
          show: false,
        },
      },
      top: 80,
      data: [
        {
          name: "sss",
          value: "21",
        },
        {
          name: "22",
          value: "11",
        },
        {
          name: "11",
          value: "11",
        },
        {
          name: "222",
          value: "21",
        },
        {
          name: "1111",
          value: "11",
        },
        {
          name: "233",
          value: "233",
        },
        {
          name: "2222",
          value: "233",
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
}
export const getIndustryHotBarOption = (names, datas) => {
  return {
    xAxis: {
      max: "dataMax",
      show: false,
    },
    yAxis: {
      type: "category",
      data: names,
      inverse: true,
      animationDuration: 300,
      animationDurationUpdate: 300,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        textStyle: {
          color: "#333", //更改坐标轴文字颜色
          fontSize: 16, //更改坐标轴文字大小
        },
      },
    },
    series: [
      {
        realtimeSort: true,
        name: "X",
        type: "bar",
        barWidth: 20,
        data: datas,
        label: {
          show: true,
          position: "right",
          valueAnimation: true,
          color: "#fd0000",
          fontWeight: "bold",
          fontSize: 16,
          formatter: "{c}%" + "↑",
        },
        itemStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#F1F8FF", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "#49A3E3", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    grid: {
      left: "100px",
      right: "100px",
      top: 0,
    },
    animationDuration: 0,
    animationDurationUpdate: 3000,
    animationEasing: "linear",
    animationEasingUpdate: "linear",
  }
}
export const getIndustryHotPieOption = (datas) => {
  let names = []
  datas.map((item) => {
    names.push(item.name)
  })
  return {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      type: "plain",
      // orient: 'vertical',
      top: "30%",
      left: "50%",
      // right: 0,
      // top: 20,
      // bottom: 20,
      data: names,
      icon: "circle",
      itemGap: 22,
      textStyle: {
        fontSize: 16,
        color: "#333",
      },
    },
    series: [
      {
        name: "",
        type: "pie",
        radius: "90%",
        center: ["20%", "30%"],
        label: {
          normal: {
            show: false,
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        top: 80,
        data: datas,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }
}
