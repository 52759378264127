import React from "react"
import { NavLink, Link } from "react-router-dom"
import styled from "styled-components"
import share from "../../assets/imgs/report/bg.png"
import search from "../../assets/imgs/report/search.png"
import search_a from "../../assets/imgs/report/search_a.png"
import ReportDetail from "./detail"
import {
  message,
  Spin,
  Radio,
  Pagination,
  DatePicker,
  Space,
  Form,
  Input,
  Col,
  Row,
  Switch,
  Select,
  Button,
} from "antd"
import {
  CaretUpOutlined,
  CaretDownOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import api from "../../util/api"
import config from "../../util/config"
import CheckableTag from "antd/lib/tag/CheckableTag"
import { thisExpression, throwStatement } from "@babel/types"
import moment from "moment"

const { RangePicker } = DatePicker

const Box = styled.div`
  background: #f4f5f7;
  min-height: 100vh;
  font-size: 17px;

  .link{
    font-size: 16px;
      color: #333333;
      font-weight: bold
    }
  .top {
    background: #f4f5f7;
    border-bottom: 1px solid #cdced0;
    position: sticky;
    top: 68px;
    left: 0;
    z-index: 100;
  }
  .contarin {
    width: 1400px;
    margin: 0 auto;
    height: 56px;
    ul {
      padding: 0 60px;
      display: flex;
      align-items: center;
      li {
        line-height: 56px;
        margin-right: 81px;
        cursor: pointer;
        font-size: 17px;
        color: #585654;
        position: relative;
        &.active {
          color: #3c81ce;
          &:after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: #1769c5;
          }
        }
      }
    }
  }
  .list {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    background: url(${share}) no-repeat;
    background-size: 100%;
    min-height: 50vh;
    .list_content {
      flex: 1;
      width: 1160px;
      margin: 0 auto;
      /* padding-top: 83px;*/
      .reportList {
        padding: 28px 70px;
        background: #fff;
        ul {
          color: #585654;
          li {
            display: inline-block;
            cursor: pointer;
            margin: 0 17px;
            position: relative;
            &:after {
              content: " ";
              position: absolute;
              height: 17px;
              width: 1px;
              right: -18px;
              top: 50%;
              transform: translateY(-50%);
              background: #585654;
            }
            &:last-child {
              &:after {
                display: none;
              }
            }
            &.active {
              &:before {
                content: " ";
                position: absolute;
                width: 100%;
                height: 1px;
                background: #585654;
                bottom: 0;
                left: 0;
              }
            }
          }
        }
      }
      .title {
        margin: 22px 0 0px 0px;

        height: 50px;
      }
      .search {
        padding: 30px 20px 0px 20px;

        .search_ipt {
          display: flex;
          cursor: pointer;
          align-items: center;
          ._ipt {
            flex: 1;
            display: flex;
            p {
              input {
                width: 100%;
                height: 100%;
                border: none;
                padding: 0 10px;
                outline: none;
              }
              &:first-child {
                flex: 1;
                height: 47px;
                background: #f7fafd;
                border: 1px solid #585654;
              }
              &:last-child {
                width: 130px;
                height: 47px;
                background: url(${search}) no-repeat;
                background-size: 100% 100%;
                border-radius: 0px 3px 3px 0px;
                &:active {
                  background: url(${search_a}) no-repeat;
                  background-size: 100% 100%;
                }
              }
            }
          }
          ._iptdate {
            flex: 1;
            display: flex;
            p {
              div {
                width: 100%;
                height: 100%;
                border: none;
                padding: 0 10px;
                outline: none;
              }
              &:first-child {
                flex: 1;
                height: 47px;
                background: #f7fafd;
                border: 1px solid #585654;
              }
              &:last-child {
                width: 130px;
                height: 47px;
                background: url(${search}) no-repeat;
                background-size: 100% 100%;
                border-radius: 0px 3px 3px 0px;
                &:active {
                  background: url(${search_a}) no-repeat;
                  background-size: 100% 100%;
                }
              }
            }
          }
          ._sbtn {
            &:active {
              box-shadow: 0 0 5px #aaa;
            }
            width: 71px;
            margin-left: 22px;
            line-height: 47px;
            color: #ffffff;
            text-align: center;
            background: linear-gradient(90deg, #49a3e3 0%, #317acc 100%);
            border-radius: 0px 3px 3px 0px;
            border: 1px solid #49a3e3;
          }
          ._btn {
            &:active {
              box-shadow: 0 0 5px #aaa;
            }
            width: 151px;
            margin-left: 22px;
            line-height: 47px;
            color: #ffffff;
            text-align: center;
            background: linear-gradient(90deg, #49a3e3 0%, #317acc 100%);
            border-radius: 0px 3px 3px 0px;
            border: 1px solid #49a3e3;
          }
        }
        .search_list {
          margin-top: 29px;
          .ant-radio-wrapper {
            color: #3c81ce;
          }
        }
      }
      .table {
        margin-bottom: 30px;
        li {
          cursor: pointer;
          height: 80px;
          background: #ffffff;
          padding: 13px 22px;
          margin-top: 1px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &:hover {
            box-shadow: 0 0 10px #ccc;
          }
          a {
            font-size: 15px;
            color: #585654;
            &:first-child {
              font-size: 16px;
              color: rgb(51, 51, 51);
              font-weight: bold;
            }
            &:last-child {
              span {
                &:first-child {
                  margin-right: 46px;
                }
              }
            }
          }
          p {
            font-size: 12px;
            color: rgb(153, 153, 153);
            &:first-child {
              font-size: 16px;
              color: rgb(51, 51, 51);
              font-weight: bold;
            }
            &:last-child {
              span {
                &:first-child {
                  margin-right: 46px;
                }
              }
            }
          }
        }
      }
    }
    .recommand {
      flex: 1;
      flex: 0 0 15%;
      margin-left: 20px;
      .item {
        margin-bottom: 21px;
        background: #fff;
        padding: 0 27px;
        .item_label {
          line-height: 75px;
          border-bottom: 1px solid #e9e9e9;
          font-size: 20px;
          font-weight: bold;
        }
      }
      .item_list {
        padding: 16px 0;
        font-size: 16px;
        font-weight: 400;
        color: #585654;
        line-height: 30px;
        display: flex;
        flex-wrap: wrap;
        p {
          width: 90%;
          text-align: center;
          &.active {
            color: #3c81ce;
            &:after {
              content: " ";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background: #1769c5;
            }
          }
        }
      }
      .item_column {
        padding: 26px 0;
        font-size: 17px;
        font-weight: 300;
        line-height: 22px;
        p {
          margin-bottom: 29px;
          overflow: hidden;
          display: -webkit-box; //将元素设为盒子伸缩模型显示
          -webkit-box-orient: vertical; //伸缩方向设为垂直方向
          -webkit-line-clamp: 2; //超出3行隐藏，并显示省略号
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`

const TitleCss = styled.div`
  padding-left: 22px;
  padding-top: 12px;
  font-size: 17px;
  font-weight: bold;
  position: relative;
  color: #000000;
  background: #fff;
  &:before {
    content: "";
    width: 2px;
    height: 15px;
    background: #1769c5;
    border-radius: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`
const TitleQryBtnCss = styled.div`
  float: right;
  margin-right: 20px;
`

const Title = ({ title, className, showQry, click }) => (
  <TitleCss className={className}>
    <span style={{ "margin-left": "10px" }}>{title}</span>

    {/* <TitleQryBtnCss>
      {showQry ? (
        <CaretUpOutlined style={{ color: "#3c81ce" }} onClick={click} />
      ) : (
        <CaretDownOutlined style={{ color: "#3c81ce" }} onClick={click} />
      )}
    </TitleQryBtnCss> */}
  </TitleCss>
)

class Meeting extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      pageIndex: 1,
      total: 0,
      showQry: true,
      showDetail: false,
      isSimQry: true,
      simpType: "",
      dateS_sim: "",
      dateE_sim: "",
      currentMenuId: "",
      tabValue: 1,
      menuIndex: 0,
      loading: true,
      reportList: [],
      industryList: [],
      reqValues: {},
      detailId: [],
      dateRageValue: 180,
      menuList: [
        {
          label: "全部会议",
          value: "",
          list: [],
          pageIndex: 1,
          total: 0,
        },
      ],
      tabList: [],
      qryText:'',
    }
  }
  componentDidMount() {
    // setTimeout(() => {
    //   this.setState({
    //     loading: false,
    //   })
    // }, 1000)
    console.log("this.props.params.data==", this.props.params)
    let menuId = this.props.location.state
      ? api.convertNull(this.props.location.state.clickMenuId)
      : ""
    console.log("clickMenuId===", menuId)
    if (menuId != "") {
      this.setState({
        currentMenuId: menuId,
      })
    } else {
      menuId = this.state.currentMenuId
    }

    if (api.checkLogin()) {
      this.getMeetingByMenuId(menuId)
      this.getMeetingMenu()
    } else {
      message.error("请登陆")
      this.props.history.push("/")
    }
  }
  getMeetingMenu() {
    let menus = api.localGet(config.meeting_menu_key)
    if (menus && menus.length > 0) {
      this.setState({
        menuList: JSON.parse(menus),
      })
    } else {
      api.getMeetingMenu().then((res) => {
        if (res && res.Table && res.Table.length > 0) {
          let menuList = this.state.menuList
          if (menuList.length == 1) {
            res.Table.map((item) => {
              menuList.push({
                label: item.NAME,
                value: item.CODE,
                list: [],
                pageIndex: 1,
                total: 0,
              })
            })
          }
          api.localSave(config.meeting_menu_key, JSON.stringify(menuList))
          this.setState({
            menuList: menuList,
          })
        }
      })
    }
  }

  setMenuList(reportList, total) {
    let menuList = this.state.menuList
    let menuIndex = this.state.menuIndex
    menuList[menuIndex].list = reportList
    menuList[menuIndex].total = total
    menuList[menuIndex].pageIndex = this.state.pageIndex
    this.setState({
      menuList: menuList,
    })
  }
  doQueryMeeting(fType, pageIndex) {
    let qryText = this.refs.txtQryReport.value
    this.setState({
      loading: true,
      qryText:qryText,
    })
    let qryDateE = moment().format(config.dateFormat)
    let nDays = this.state.dateRageValue
    let qryDateS =
      nDays == 0
        ? ""
        : moment(moment().subtract(nDays, "days")).format(config.dateFormat)
    api
      .getMeeting(fType, pageIndex, qryText, qryDateS, qryDateE)
      .then((res) => {
        console.log("doQueryMeeting res==", res)
        let reportList = []
        let total = 0
        if (res && res.Table) {
          res.Table.map((item) => {
            reportList.push({
              title: item.FTITLE,
              guid: item.GUID,
              source: item.FSOURCE,
              author: item.AUTHOR,
              time: item.CREATETIME,
            })
            total = item.ALLCOUNT
          })
        }
        this.setState({
          reportList: reportList,
          total: total,
          loading: false,
        })
        this.setMenuList(reportList, total)
        api.localSave(config.meeting_more_key, JSON.stringify(reportList))
      })
      .catch((e) => {
        this.setState({
          loading: false,
        })
      })
  }
  onKeyupRes(e){
    if(e.keyCode === 13) {
       this.simQuery()
     }
  }
  simQuery() {
    this.setState({
      pageIndex: 1,
    })
    this.doQueryMeeting(this.state.currentMenuId, 1)
  }

  getMeetingByMenuId(menuId) {
    console.log("menuId==", menuId)
    this.doQueryMeeting(menuId, this.state.pageIndex)
  }
  changeTab(v) {
    this.setState({
      tabValue: v,
      loading: true,
    })
    setTimeout(() => {
      this.setState({
        loading: false,
      })
    }, 1000)
  }
  changeMenu(v, index) {
    console.log("v===", v)
    console.log("index===", index)
    let selectMenu = this.state.menuList[index]
    this.setState({
      currentMenuId: v,
      menuIndex: index,
      showDetail: false,
      pageIndex: selectMenu.pageIndex,
      reportList: selectMenu.list,
    })
    console.log("selectMenu==", selectMenu)
    if (this.state.currentMenuId == v) return
    if (selectMenu.list.length == 0) {
      this.getMeetingByMenuId(v)
    }
  }
  goDetail(guid, index) {
    //this.props.history.push("/report/detail/" + guid)
    //this.renderDetail(guid)
    // this.setState({
    //   showDetail: true,
    //   detailId: guid,
    // })

    let reportList = this.state.reportList
    api.localSave(config.meeting_more_key, JSON.stringify(reportList))
    // this.props.history.push({
    //   pathname: "/meeting/detail/" + guid,
    //   more: { reportList }
    // })
    window.open("/meeting/detail/" + guid, "_blank")

    //this.context.router.history.push(`/report/detail/${guid}`)
  }
  detailMenuClick(clickMenuId, index) {
    console.log("clickMenuId==", clickMenuId)
    this.setState({
      showDetail: false,
    })
  }
  onChange = (e) => {
    const value = e.target.value
    console.log("rdio value=", value)
    this.setState({
      dateRageValue: value,
    })
    console.log("this.state.dateRageValue===", this.state.dateRageValue)
  }
  onDateChange_sim(value, dateString) {
    console.log("Selected Time: ", value)
    console.log("Formatted Selected Time: ", dateString)
    this.setState({
      dateS_sim: dateString[0],
      dateE_sim: dateString[1],
    })
  }
  industryChange(selectIndustry) {
    console.log("selectIndustry==", selectIndustry)
  }
  onFormFinish(values) {
    console.log("values===", values)
    this.setState({
      reqValues: values,
    })
    this.seqQuery(values)
  }
  btnShowQry() {
    this.setState({
      showQry: !this.state.showQry,
    })
  }
  btnReqQry() {
    this.setState({
      isSimQry: !this.state.isSimQry,
    })
  }
  onSwitchChange(checked) {
    console.log("isHis=", checked)
  }

  onDateOk_sim(value) {
    console.log("onOk: ", value)
  }
  onPageChange(current) {
    console.log("current=", current)
    this.setState({
      pageIndex: current,
    })
    this.doQueryMeeting(this.state.currentMenuId, current)
  }

  render() {
    const {
      tabList,
      tabValue,
      loading,
      menuIndex,
      currentMenuId,
      pageIndex,
      total,
      menuList,
      reportList,
      simpType,
      isSimQry,
      industryList,
      showQry,
      showDetail,
      detailId,
      dateRageValue,
      qryText,
    } = this.state
    return (
      <Box>
        {/* <div className="top">
          <div className="contarin">
            <ul>
              {tabList.map((t, i) => {
                return (
                  <li
                    key={"tab-" + i}
                    className={tabValue === t.value ? "active" : ""}
                    onClick={() => this.changeTab(t.value)}
                  >
                    {t.label}
                  </li>
                )
              })}
            </ul>
          </div>
        </div> */}
        <Spin spinning={loading}>
          <div className="list">
            <div
              style={{
                background:
                  "linear-gradient(to bottom, #E5F1FF 0%, transparent 20%)",
              }}
            >
              <div className="list_content">
                {/* <div className="reportList">
                  <ul>
                    {menuList.map((t, i) => {
                      return (
                        <li
                          key={t.value}
                          className={value === t.value ? "active" : ""}
                          onClick={() => this.changeReport(t.value, i)}
                        >
                          {t.label}
                        </li>
                      )
                    })}
                  </ul>
                </div> */}

                {showQry ? (
                  <div>
                    {isSimQry ? (
                      <div className="search">
                        <div className="search_ipt">
                          <section
                            className={`${
                              simpType == "9" ? "_iptdate" : "_ipt"
                            }`}
                          >
                            <p>
                              {simpType == "9" ? (
                                <RangePicker
                                  locale={locale}
                                  onChange={this.onDateChange_sim}
                                  onOk={this.onDateOk_sim}
                                />
                              ) : (
                                <input
                                  placeholder="输入搜索关键词"
                                  ref="txtQryReport" onKeyUp={this.onKeyupRes.bind(this)}
                                />
                              )}
                            </p>
                            <p onClick={() => this.simQuery()}></p>
                          </section>

                          {/* <div
                              className="_btn"
                              onClick={() => this.btnReqQry()}
                            >
                              高级搜索
                            </div> */}
                        </div>
                        <div className="search_list">
                          <Radio.Group
                            onChange={this.onChange}
                            selectedValue={dateRageValue}
                          >
                            <Radio value={"7"}>一周内</Radio>
                            <Radio value={"30"}>一个月</Radio>
                            <Radio value={"90"}>三个月</Radio>
                            <Radio value={"180"}>半年</Radio>
                            <Radio value={"365"}>一年内</Radio>
                            <Radio value={"720"}>两年内</Radio>
                            <Radio value={"0"}>所有日期</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    ) : (
                      <div className="search">
                        <Form
                          name="basic"
                          onFinish={this.onFormFinish.bind(this)}
                          autoComplete="off"
                        >
                          <Row gutter={24}>
                            <Col span={8} key={1}>
                              <Form.Item label="报告标题" name="txtReqTitle">
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={8} key={2}>
                              <Form.Item label="代码/简称" name="txtReqCode">
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={8} key={3}>
                              <Form.Item label="机构出处" name="txtReqSource">
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={24}>
                            <Col span={8} key={4}>
                              <Form.Item label="报告作者" name="txtReqAuthor">
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={8} key={5}>
                              <Form.Item label="开始时间" name="txtReqSDate">
                                <DatePicker
                                  format={config.dateFormat}
                                  locale={locale}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8} key={6}>
                              <Form.Item label="结束时间" name="txtReqEDate">
                                <DatePicker
                                  format={config.dateFormat}
                                  locale={locale}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={24}>
                            <Col span={8} key={7}>
                              <Form.Item label="所属行业" name="txtReqIndustry">
                                <Select
                                  options={industryList}
                                  onChange={this.industryChange}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8} key={8}>
                              <Form.Item label="历史研报" name="txtReqIsHis">
                                <Switch onChange={this.onSwitchChange} />
                              </Form.Item>
                            </Col>
                            <Col span={8} key={9}>
                              <Button type="primary" htmlType="submit">
                                搜索
                              </Button>{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <Button
                                type="primary"
                                onClick={() => this.btnReqQry()}
                              >
                                简单搜索
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    )}
                  </div>
                ) : (
                  <span></span>
                )}
                <Title
                  className="title"
                  click={this.btnShowQry.bind(this)}
                  showQry={showQry}
                  title={menuList[menuIndex].label}
                ></Title>

                <ul className="table">
                  {
                    //new Array(10).fill(1).map((t, i) => {
                    reportList.map((t, i) => {
                      return (
                        // <li
                        //   onClick={() => this.goDetail(t.guid, i)}
                        //   key={t.guid}
                        // >
                        //   <p>{t.title}</p>
                        <Link className="link" key={t.guid} to={{pathname:"/meeting/detail/" + t.guid,search:JSON.stringify({qryText:qryText})}} target="_blank">
                        <li
                          // onClick={() => this.goDetail(t.guid, i)}
                          key={t.guid}
                        >
                          <div
                              className="innerhtml"
                              dangerouslySetInnerHTML={{
                                __html: api.qryhightlight(t.title,qryText),
                              }}
                            ></div>
                          <p>
                            <span>来自 {t.source + "  " + t.author}</span>
                            <span>{t.time}</span>
                          </p>
                        </li>
                        </Link>
                      )
                    })
                  }
                </ul>
                <div style={{ paddingBottom: "85px" }}>
                  <Pagination
                    className="ant-table-pagination ant-table-pagination-center"
                    current={pageIndex}
                    pageSize={config.pageSize}
                    total={total}
                    showSizeChanger={false}
                    onChange={this.onPageChange.bind(this)}
                  ></Pagination>
                </div>
              </div>
            </div>

            <div className="recommand">
              <div className="item">
                <p className="item_label">机构会议</p>
                <div className="item_list">
                  {
                    /* {reportList.map((t, i) => {
                    return <p key={t.value}>{t.label}</p>
                  })} */
                    menuList.map((t, i) => {
                      return (
                        <p
                          key={t.value}
                          className={currentMenuId === t.value ? "active" : ""}
                          onClick={() => this.changeMenu(t.value, i)}
                        >
                          {t.label}
                        </p>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Box>
    )
  }
}
export default Meeting
