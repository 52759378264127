import React from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { BackTop } from "antd"
import Routes from "../router"
import routesList from "../router/route"
import styled from "styled-components"
import { NavLink, Link } from "react-router-dom"
import police from "../assets/imgs/index/police.png"
import logo from "../assets/imgs/index/hxnn.png"
import logoklcj from "../assets/imgs/index/klcj.png"
const MainCss = styled.div`
  header {
    background: #234870;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  .hearder_contarin {
    width: 1160px;
    padding: 0 10px;
    margin: 0 30px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo_nav {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    p {
      cursor: pointer;
      margin-right: 20px;
    }
    nav {
      width: 1160px;
      display: flex;
      align-items: center;
      .link {
        cursor: pointer;
        height: 68px;
        line-height: 68px;
        display: block;
        padding: 0 20px;
        color: #fff;
        &:hover {
          background: #49a3e3;
        }
      }
      .a {
        background: #3c81ce;
      }
    }
    p {
      cursor: pointer;
      margin-right: 20px;
    }
  }
  .user {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
  }
  footer {
    background: #234870;
    font-size: 16px;
    color: #fff;
    .footer_content {
      padding: 0 10px;
      width: 1160px;
      height: 116px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      .footer_left,
      .footer_right {
        height: 60px;
      }
      .footer_left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div {
          display: flex;
          align-items: center;
          img {
            width: 18px;
            display: block;
            margin-right: 5px;
          }
        }
      }
      .footer_right {
        ul {
          display: flex;
          align-items: center;
          li {
            margin: 0 31px;
            font-weight: 200;
          }
          .link {
            color: #fff;
          }
        }
      }
    }
  }
`

export const navList = [
  {
    path: "/",
    name: "首页",
    showNav:true,
  },
  {
    path: "/report",
    name: "机构研报",
    showNav:true,
    desc: "提供中国市场，港股市场和中概股等金融市场最全面的研究报告库,将450多家研究机构的研究报告优化整合,第一时间为客户提供各大券商研究所报告之精华,包括摘要、原文，数据；实现标签化检索及各种分类；深入解析上市公司、行业、热点、宏观、策略等最新变化和价值研究方向",
  },
  {
    path: "/meeting",
    name: "机构会议",
    showNav:true,
    desc: "机构内部的电话会议，调研纪要，投研笔记，领先于公开研报的一手信息，一周近500篇，平均每天超过70篇，内容包括各个行业，热门公司，敏感数据，与机构投资者站在同一起跑线",
  },
  {
    path: "/goldStock",
    name: "机构金股",
    showNav:true,
    desc: "汇集各大券商研究所月度金股，集中呈现各家研究所月度重点推荐。可以横向纵向搜索各家券商推荐的股票组合",
  },
  {
    path: "/events",
    name: "机构事件",
    showNav:true,
    desc: "汇集市场最全的投研活动事件信息，每天百场投研活动(电话会议/调研/路演/策略会/会议）在本平台发布，数万名专家智库，行业分析师助您全产业链360°视角审视公司以及行业发展",
  },
  {
    path: "/data",
    name: "机构数据",
    showNav:true,
    desc: "慧选牛牛与Tushare 战略结盟，打造专业版《慧选 Tushare Pro》平台 ，服务机构投资者和金融工程分析师，包括股票、基金、期货、数字货币等",
  },
  {
    path: "/group",
    name: "机构社群",
    showNav:true,
    desc: "搭建买卖双方投研交互桥梁，实现“没有信息差，机构零距离",
  },
  {
    path: "/data",
    name: "《慧选 Tushare Pro》数据平台",
    showNav:false,
    desc: "数据丰富：拥有丰富的数据内容，如股票、基金、期货、数字货币等行情数据，公司财务、基金经理等基本面数据<br>获取简单：SDK开发包支持语言，同时提供HTTP Restful接口，最大程度方便不同人群的使用"
    +"<br>落地方便：提供多种数据储存方式，如Oracle、MySQL，MongoDB、HDF5、CSV等，为数据获取提供了性能保证",
  },
  {
    path: "/tpsNews",
    showNav:true,
    name: "台湾电子",
  },
  {
    path: "/paidInfo",
    showNav:true,
    name: "知识付费",
  },
  // {
  //   path: "http://www.longboinfo.com/download",
  //   showNav:true,
  //   name: "论坛",
  // },
  // {
  //   path: "/mine",
  //   showNav:true,
  //   name: "我的信息",
  // },
]

class Main extends React.PureComponent {
  constructor() {
    super()
    this.state = {}
  }
  render() {
    return (
      <MainCss>
        <BackTop />
        <Router>
          <header>
            <div className="hearder_contarin">
              <div className="logo_nav">
                <p>
                  <Link to="/">
                    <img src={logoklcj} alt="开利财经" />
                  </Link>
                </p>
                <nav>
                  {navList.map((t, i) => (
                    t.showNav ? (
                      t.path.startsWith('http') ? (
                        <a  className="link" href={t.path} target="_blank" > 下载中心 </a>
                      ) : (
                        <NavLink
                          exact={t.path === "/" ? true : false}
                          className="link"
                          to={t.path}
                          key={i}
                          activeClassName="a"
                        >
                          {t.name}
                        </NavLink>
                        )
                     ) :null
                  ))}
                </nav>
              </div>
              {/* <div className="user">
                <p>下载app</p>
                <img src="" alt="" />
              </div> */}
            </div>
          </header>
          {/* <Routes routesList={routesList} /> */}
          {routesList.map((item) => {
            return (
              <Route
                exact={item.exact}
                path={item.path}
                key={item.path}
                render={(props) => (
                  <item.component {...props} routes={item.children} />
                )}
              />
            )
          })}
          <footer>
            <div className="footer_content">
              <p>
                <Link to="/">
                  <img src={logo} alt="慧选牛牛" />
                </Link>
              </p>
              <div className="footer_left">
                <div><a href="https://beian.miit.gov.cn/" target="_blank">
                  <img src={police} alt="" />
                  <p>沪ICP备05008792号-6</p> </a>
                </div>
                <div>上海龙柏信息科技有限公司版权所有2010-2021</div>
              </div>

              <div className="footer_right">
                <ul>
                  <li>
                    <Link className="link" to="/aboutUs">
                      关于我们
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/contactUs">
                      联系我们
                    </Link>
                  </li>
                  <li>
                    <a
                      className="link"
                      href="http://www.longboinfo.com/download"
                      target="_blank"
                    >
                      下载中心
                    </a>
                  </li>
                  <li>
                    <Link className="link" to="/mine">
                        我的信息
                    </Link>
                    </li>
                  {/* <li>付款</li>
                  <li>APP下载</li>
                  <li>个人信息</li> */}
                </ul>
              </div>
            </div>
          </footer>
        </Router>
      </MainCss>
    )
  }
}

export default Main
