import React from "react"
import styled from "styled-components"
import { NavLink, Link } from "react-router-dom"
import { Spin, message, Divider } from "antd"
import "./style/detail.scss"
import { ImportOutlined } from "@ant-design/icons"
import user from "../../assets/imgs/report/user.png"
import pdf from "../../assets/imgs/report/pdf.png"
import { reportList } from "./index"
import api from "../../util/api"
import { throwStatement } from "@babel/types"
import config from "../../util/config"

class ReportDetail extends React.PureComponent {
  constructor(props) {
    super(props)
    let search = this.props.location.search
    search = search.substr(1,search.length)
    const qryParam = JSON.parse(decodeURI(search));

    this.state = {
      qryText: qryParam.qryText,
      idx: 1,
      loading: true,
      report: {},
      moreList: [],
      currentGuid: "",
      currentMenuId: "",
      menuList: [], //this.props.menuList,
      tabList: [
        {
          label: "机构关注研报",
          value: 1,
        },
        {
          label: "热门行业研报",
          value: 2,
        },
        {
          label: "首次评级研报",
          value: 3,
        },
      ],
    }
  }
  componentDidMount() {
    let guid = this.props.match.params.id //this.props.guid //this.props.match.params.id
    //console.log("this.props.guid==", this.props.guid)
    //console.log("this.props.menuList==", this.props.menuList)
    if (api.checkLogin()) {
      this.getReportDetail(guid)
      let menuList = api.localGet(config.menu_key)
      console.log("menuList from local==", menuList)
      if (menuList && menuList.length > 0) {
        this.setState({
          menuList: JSON.parse(menuList),
        })
      }
    } else {
      message.error("请登陆")
      this.props.history.push("/")
    }
  }
  getMoreReport(qryText, qryType) {
    api.getMoreReport(qryText, qryType).then((res) => {
      if (res && res.Table && res.Table.length > 0) {
        let moreList = res.Table.filter((t) => {
          return t.GUID != this.state.currentGuid
        })
        this.setState({
          moreList: moreList,
        })
      }
    })
  }
  changeMenu(clickMenuId, index) {
    console.log("detail clickMenuId===", clickMenuId)
    console.log("call father..")
    //this.props.detailMenuClick(v)
    // this.props.changeMenu(v, index)
    console.log("this.props", this.props)
    this.props.history.push({ pathname: "/report", state: { clickMenuId } })
  }
  goDetail(guid) {
    this.getReportDetail(guid)
  }
  getReportDetail(guid) {
    console.log("guid==", guid)
    this.setState({
      loading: true,
      currentGuid: guid,
    })
    api
      .getReportDetail(guid)
      .then((res) => {
        console.log("res===", res)
        let report =
          res && res.Table && res.Table.length > 0 ? res.Table[0] : {}
        report.SUMMARY =
          api.convertNull(report.SUMMARY) == ""
            ? ""
            : api.splitContent(api.convetHtml(report.SUMMARY))
            report.SUMMARY = api.qryhightlight(report.SUMMARY,this.state.qryText)
        this.setState({
          report: report,
          currentMenuId: report.MENUID,
        })
        let qryText = report.AUTHOR == "" ? report.FSOURCE : report.AUTHOR
        let qryType = report.AUTHOR == "" ? "出处" : "作者"
        this.getMoreReport(qryText, qryType)
        setTimeout(() => {
          this.setState({
            loading: false,
          })
        }, 1000)
      })
      .catch((error) => {
        setTimeout(() => {
          this.setState({
            loading: false,
          })
        }, 1000)
      })
  }
  changeTab(v) {
    this.setState({
      idx: v,
      loading: true,
    })
    setTimeout(() => {
      this.setState({
        loading: false,
      })
    }, 1000)
  }
  openPdf() {
    api.saveDown(this.state.report)
    let pdf =
      "http://www.huijuzx.com/weixinpt/uploads/info/" +
      this.state.report.FILEPATH
    console.log("pdf==", pdf)
    window.open(pdf, "_blank")
  }
  render() {
    const { tabList, idx, loading, report, moreList, menuList, currentMenuId,qryText } =
      this.state
    return (
      <div className="box">
        <Spin spinning={loading}>
          {/* <div className="top">
            <div className="contarin">
              <ul>
                {tabList.map((t) => {
                  return (
                    <li
                      key={t.value}
                      className={idx === t.value ? "active" : ""}
                      onClick={() => this.changeTab(t.value)}
                    >
                      {t.label}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div> */}
          <div className="text-content maxContrain">
            <div className="text">
              <p className="title">
                {report.FTITLE}
                {/* <ImportOutlined
                  onClick={() =>
                    this.changeMenu(
                      this.props.currentMenuId,
                      this.props.currentMenuIndex
                    )
                  }
                  style={{
                    float: "right",
                    margin: "10px",
                    color: "#3c81ce",
                  }}
                /> */}
              </p>
              <div className="tag">
                <p className="resource">
                  <span>{report.FSOURCE}</span>
                  {/* <section>{report.REPORTTYPENAME}</section> */}
                </p>
                {report.REPORTTYPENAME ? (
                  <div className="resource">
                    <section>{report.REPORTTYPENAME}</section>
                  </div>
                ) : null}
                <p className="createUser">
                  <img src={user} alt="" />
                  {report.AUTHOR}
                </p>

                {report.INDUSTRYNAME ? (
                  <section className="stock">{report.INDUSTRYNAME}</section>
                ) : null}

                {report.STOCKNAME ? (
                  <p className="stock">个股：{report.STOCKNAME}</p>
                ) : null}

                {report.INVESTNAME ? (
                  <p className="stock">评级：{report.INVESTNAME}</p>
                ) : null}
                {/* <p className="stock">个股：{report.STOCKNAME}</p>
                <p className="level">评级：{report.INVESTNAME}</p> */}
              </div>
              <p className="time1">{report.CREATEDATE1}</p>
              <p className="pdf">
                <img src={pdf} alt="" />
                {report.FILEPATH ? (
                  <a href="#!" onClick={() => this.openPdf()}>
                    {report.FILENAME}
                  </a>
                ) : (
                  <span>{report.FILENAME}</span>
                )}
              </p>
              <Divider orientation="left" plain>
                <div class="ipyGSt">摘要</div>
              </Divider>

              <div
                className="innerhtml"
                dangerouslySetInnerHTML={{
                  __html: report.SUMMARY,
                }}
              ></div>
            </div>
            <div className="recommand">
              <div className="item">
                <p className="item_label">研究报告</p>
                {menuList ? (
                  <div className="item_list">
                    {menuList.map((t, i) => {
                      return (
                        <p
                          key={t.value}
                          className={currentMenuId === t.value ? "active" : ""}
                          onClick={this.changeMenu.bind(this, t.value, i)}
                        >
                          {/* <Link
                            to={{
                              pathname: "/report",
                              query: { clickMenuId: t.value },
                            }}
                          > */}
                          {t.label}
                          {/* </Link> */}
                        </p>
                      )
                    })}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="item">
                <p className="item_label">推荐阅读</p>
                <div className="item_column">
                  {
                    //new Array(5).fill(1).map((t, i) => {
                    moreList.map((t, i) => {
                      return (
                        <p key={i} onClick={() => this.goDetail(t.GUID, i)}>
                          {t.FTITLE}
                        </p>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    )
  }
}
export default ReportDetail
