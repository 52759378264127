import React from "react"
import { NavLink, Link } from "react-router-dom"
import styled from "styled-components"
import {
  message,
  Spin,
  Radio,
  Pagination,
  DatePicker,
  Space,
  Form,
  Input,
  Col,
  Row,
  Switch,
  Select,
  Button,
  Descriptions,
  Table,
  Image,
  Menu,
  Modal,
  List,
  Skeleton,
  Avatar,
} from "antd"
import {
  EditTwoTone,
  SearchOutlined,
  SolutionOutlined,
  CreditCardOutlined,
  SolutionTwoTone,
  StarTwoTone,
  IeOutlined,
  ExclamationCircleOutlined,
  tableData_award_detail,
} from "@ant-design/icons"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import api from "../../util/api"
import { thisExpression, throwStatement } from "@babel/types"
import moment from "moment"
const { Column } = Table
const { confirm } = Modal

const IndexCss = styled.div`
  padding: 20px;
  .btn {
    margin-left: 40px;
  }
  .divh {
    padding-top: 10px;
  }
`
const TitleCss = styled.div`
  padding-left: 22px;
  padding-top: 7px;
  font-size: 17px;
  font-weight: bold;
  position: relative;
  height: 40px;
  color: #000000;
  background: #fff;
  &:before {
    content: "";
    width: 2px;
    height: 15px;
    background: #1769c5;
    border-radius: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`
const TitleQryBtnCss = styled.div`
  float: right;
  margin-right: 20px;
`

const Title = ({ title, className, showQry, click }) => (
  <TitleCss className={className}>
    &nbsp;&nbsp;<span>{title}</span>
    {/* <TitleQryBtnCss>
      {showQry ? (
        <CaretUpOutlined style={{ color: "#3c81ce" }} onClick={click} />
      ) : (
        <CaretDownOutlined style={{ color: "#3c81ce" }} onClick={click} />
      )}
    </TitleQryBtnCss> */}
  </TitleCss>
)

class GroupDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      guid: "",
      awardTabIndex: 0,
      loading: false,
      isModalVisible: false,
      txtKard: "",
      gdTable: [],
      corp: {},
      zzTable: [],
      ggdata: [],
      data: [],
      list: [],
      tableData_award_detail:[],
      initLoading: false,
    }
  }

  componentDidMount() {
    let guid = api.decode(this.props.match.params.id)
    let awardTabIndex = api.decode(this.props.match.params.award)
    this.setState({
      loading: true,
      guid: guid,
      awardTabIndex: parseInt(awardTabIndex)
    })
    this.getList()
    api.getCorpGroupDetail(guid).then((res) => {
      console.log("corp111 ress=", res)
      this.setState({
        loading: false,
      })
      if (res) {
        let corp = res.Table && res.Table.length > 0 ? res.Table[0] : {}
        let gdTable = res.Table1 ? res.Table1 : []
        let ggdata = res.Table2 ? res.Table2 : []
        let zzTable =
          ggdata.length > 0
            ? ggdata.filter((t) => {
                return t.Label == "最终受益人"
              })
            : []
        if (res.Table3 && res.Table3.length > 0) {
          ggdata.map((t) => {
            t.works = res.Table3.filter((r) => {
              return t.PracticeNumber == r.PracticeNumber
            })
          })
        }
       // console.log("ggdata==", ggdata)

        this.setState({
          corp: corp,
          gdTable: gdTable,
          ggdata: ggdata,
          zzTable: zzTable,
        })
      }
    })

    this.getAward(guid,awardTabIndex)
  }
  getAward(guid,awardIndex){
    api
      .getCorpAwardDetail(
        awardIndex,
      '',
      1,
      1000,
      guid
      )
      .then((res) => {
        if (res) {
          let tableData = res && res.data ? res.data : []
          let total = res && res.total ? res.total : []
          this.setState({
            tableData_award_detail: tableData,
            loading: false,
          })
        }
      })
  }
  getList() {
    let listData = []
    for (let i = 0; i < 23; i++) {
      listData.push({
        href: "https://ant.design",
        title: `ant design part ${i}`,
        avatar: "https://joeschmoe.io/api/v1/random",
        description:
          "Ant Design, a design language for background applications, is refined by Ant UED Team.",
        content:
          "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
      })
    }
    this.setState({
      initLoading: false,
      list: listData,
    })
  }

  render() {
    const {
      loading,
      corp,
      gdTable,
      zzTable,
      ggdata,
      isModalVisible,
      data,
      list,
      initLoading,
      awardTabIndex,
      tableData_award_detail,
    } = this.state
    const loadMore =
      !initLoading && !loading ? (
        <div
          style={{
            textAlign: "center",
            marginTop: 12,
            height: 32,
            lineHeight: "32px",
          }}
        >
          <Button onClick={this.onLoadMore}>loading more</Button>
        </div>
      ) : null

    return (
      <IndexCss>
        <Spin spinning={loading}>
          <div className="divh"></div>
          <Descriptions
            title=""
            bordered
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            style={{ margin: "10px" }}
            className="table"
          >
            <Descriptions.Item label="联系方式">
              <div className="gzh-box2">
                <div className="img-box">
                  <Image src="https://www.huijuzx.com/images/no.png" />
                </div>
                <div className="txt-box">
                  <p className="tit">
                    <a target="_blank" uigs="account_name_0" href="/">
                      <em>{corp.AbbrName}</em>
                    </a>
                  </p>
                  <p className="info">
                    公众号：
                    {corp.PublicCode ? (
                      <a
                        href="#!"
                        onClick={() =>
                          api.goWebSite(
                            "https://weixin.sogou.com/weixin?type=1&s_from=input&query=" +
                              corp.PublicCode
                          )
                        }
                      >
                        {corp.PublicCode}
                      </a>
                    ) : null}
                    <span className="line-s"></span>
                    {corp.WebSite ? (
                      <span>
                        <IeOutlined />
                        &nbsp;&nbsp;
                        <a
                          href="#!"
                          onClick={() => api.goWebSite(corp.WebSite)}
                        >
                          机构网址
                        </a>
                        <span className="line-s"></span>
                      </span>
                    ) : null}
                    <SearchOutlined twoToneColor="#eb2f96" />
                    &nbsp;&nbsp;
                    <a
                      href="#!"
                      onClick={() =>
                        api.goWebSite(
                          "https://www.baidu.com/s?wd=" + corp.AbbrName
                        )
                      }
                    >
                      百度
                    </a>
                    <span className="line-s"></span>
                    <CreditCardOutlined twoToneColor="#eb2f96" />
                    &nbsp;&nbsp;
                    <Button type="link" onClick={this.showModal.bind(this)}>
                      递名片
                    </Button>
                    <span className="line-s"></span>
                    {/*<StarTwoTone twoToneColor="#eb2f96" />
                    &nbsp;&nbsp;
                     <Button type="link" onClick={this.goVip.bind(this)}>
                      VIP通道
                    </Button>
                    <span className="line-s"></span>
                    <EditTwoTone twoToneColor="#9ecaf0" />
                    &nbsp;&nbsp;
                    <Button type="link" onClick={this.goBusiness.bind(this)}>
                      业务发布
                    </Button> */}
                  </p>
                </div>
              </div>
            </Descriptions.Item>
          </Descriptions>
          <Modal
            title="递名片"
            visible={isModalVisible}
            onOk={this.handleOkModal.bind(this)}
            onCancel={this.handleCancelModal.bind(this)}
            okText="确认"
            cancelText="取消"
          >
            <Input.TextArea
              showCount
              placeholder="请输入消息内容，如个人信息、公司信息、联系方式"
              rows={7}
              onChange={this.handelKardChange.bind(this)}
            />
          </Modal>
          <Title className="title" title="机构信息"></Title>

          {corp.AgentType == "券商研究所" ? (
            <Descriptions
              title=""
              bordered
              column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
              style={{ margin: "10px" }}
              className="table"
            >
              <Descriptions.Item label="公司全称">
                {corp.CompanyName}
              </Descriptions.Item>
              <Descriptions.Item label="公司简称">
                {corp.AbbrName}
              </Descriptions.Item>
              <Descriptions.Item label="法定代表人">
                {corp.LegalPerson}
              </Descriptions.Item>
              <Descriptions.Item label="基金从业人员证书编号">
                {corp.PracticeNumber}
              </Descriptions.Item>
              <Descriptions.Item label="联系电话(企查查)">
                {corp.Phone}
              </Descriptions.Item>
              <Descriptions.Item label="联系电话(官网)">
                {corp.Phone2}
              </Descriptions.Item>
              <Descriptions.Item label="邮箱">{corp.Email}</Descriptions.Item>
              <Descriptions.Item label="登记状态">
                {corp.RegisterStatus}
              </Descriptions.Item>
              <Descriptions.Item label="成立日期">
                {corp.EstablishmentDate}
              </Descriptions.Item>
              <Descriptions.Item label="注册资本(万元)">
                {corp.RegCapital}
              </Descriptions.Item>
              <Descriptions.Item label="管理规模区间">
                {corp.ManagerScaleInter}
              </Descriptions.Item>
              <Descriptions.Item label="业务类型">
                {corp.ServiceType}
              </Descriptions.Item>
              <Descriptions.Item label="实缴资本(万元)">
                {corp.ActulCapital}
              </Descriptions.Item>
              {/* <Descriptions.Item label="营业期限">
                {corp.BusinessTerm}
              </Descriptions.Item> */}
              <Descriptions.Item label="机构类型">
                {corp.AgentType}
              </Descriptions.Item>
              <Descriptions.Item label="地址1">
                {corp.Address}
              </Descriptions.Item>

              <Descriptions.Item label="地址2">
                {corp.Address2}
              </Descriptions.Item>
              <Descriptions.Item label="地址3">
                {corp.Address3}
              </Descriptions.Item>
              {/* <Descriptions.Item label="地址4">
                {corp.Address4}
              </Descriptions.Item>
              <Descriptions.Item label="销售电话">
                {corp.Phone_Sale}
              </Descriptions.Item>
              <Descriptions.Item label="所长">{corp.YjsHead}</Descriptions.Item>
              <Descriptions.Item label="副所长">
                {corp.YjsHead1}
              </Descriptions.Item>
              <Descriptions.Item label="最后更新时间">
                {corp.UpDatetime}
              </Descriptions.Item> */}
            </Descriptions>
          ) : (
            <Descriptions
              title=""
              bordered
              column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
              style={{ margin: "10px" }}
              className="table"
            >
              <Descriptions.Item label="公司全称">
                {corp.CompanyName}
              </Descriptions.Item>
              <Descriptions.Item label="公司简称">
                {corp.AbbrName}
              </Descriptions.Item>
              <Descriptions.Item label="法定代表人">
                {corp.LegalPerson}
              </Descriptions.Item>
              {/* <Descriptions.Item label="基金从业人员证书编号">
                {corp.PracticeNumber}
              </Descriptions.Item> */}
              <Descriptions.Item label="联系电话(企查查)">
                {corp.Phone}
              </Descriptions.Item>
              <Descriptions.Item label="联系电话(官网)">
                {corp.Phone2}
              </Descriptions.Item>
              <Descriptions.Item label="邮箱">{corp.Email}</Descriptions.Item>
              <Descriptions.Item label="登记状态">
                {corp.RegisterStatus}
              </Descriptions.Item>
              <Descriptions.Item label="成立日期">
                {corp.EstablishmentDate}
              </Descriptions.Item>
              <Descriptions.Item label="注册资本(万元)">
                {corp.RegCapital}
              </Descriptions.Item>
              {/* <Descriptions.Item label="注册资本货币单位">
                {corp.RCurrencyUnit}
              </Descriptions.Item> */}
              <Descriptions.Item label="实缴资本(万元)">
                {corp.ActulCapital}
               </Descriptions.Item>
              {/*<Descriptions.Item label="实缴资本货币单位">
                {corp.ACurrencyUnit}
              </Descriptions.Item> */}
                <Descriptions.Item label="管理规模区间">
                {corp.ManagerScaleInter}
              </Descriptions.Item>
              <Descriptions.Item label="业务类型">
                {corp.ServiceType}
              </Descriptions.Item>
              {/* <Descriptions.Item label="营业期限">
                {corp.BusinessTerm}
              </Descriptions.Item> */}
              <Descriptions.Item label="机构类型">
                {corp.AgentType}
              </Descriptions.Item>
              <Descriptions.Item label="地址">{corp.Address}</Descriptions.Item>
            </Descriptions>
          )}

          {/* <Title className="title" title="股东信息"></Title>
          <Table
            dataSource={gdTable}
            size="middle"
            rowKey={(record) => record.ShareName}
          >
            <Column
              title="序号"
              align="center"
              width={70}
              render={(text, record, index) => {
                return (
                  <div>
                    <div>{index + 1}</div>
                  </div>
                )
              }}
            />
            <Column
              title="股东及出资信息"
              align="center"
              render={(record) => {
                return <div className="link1">{record.ShareName}</div>
              }}
            />
            <Column
              title="持股比例(%)"
              align="center"
              width={120}
              sorter={(a, b) => {
                return a.EquityRatio - b.EquityRatio
              }}
              render={(record) => {
                return <div>{record.EquityRatio}</div>
              }}
            />
            <Column
              title="认缴认缴出资额(万元)"
              align="center"
              width={200}
              sorter={(a, b) => {
                return a.ContributionMoney - b.ContributionMoney
              }}
              render={(record) => {
                return <div>{record.ContributionMoney}</div>
              }}
            />{" "}
            <Column
              title="认缴出资日期"
              align="center"
              width={140}
              render={(record) => {
                return <div>{record.ContributionDate}</div>
              }}
            />
            <Column
              title="参股日期"
              align="center"
              width={140}
              render={(record) => {
                return <div>{record.InShareDate}</div>
              }}
            />
            <Column
              title="关联产品/机构"
              align="center"
              render={(record) => {
                return <div>{record.RelationName}</div>
              }}
            />
          </Table>*/}

          {/* <Title className="title" title="最终受益人"></Title>
          <Table
            dataSource={zzTable}
            loading={loading}
            size="middle"
            rowKey={(record) => record.ID}
          >
            <Column
              title="序号"
              align="center"
              width={70}
              render={(text, record, index) => {
                return <div>{index + 1}</div>
              }}
            />
            <Column
              title="最终受益人名称"
              align="center"
              width={160}
              render={(record) => {
                return <div className="link1">{record.ManagementName}</div>
              }}
            />
            <Column
              title="最终受益股份"
              align="center"
              width={160}
              render={(record) => {
                return <div>{record.FinalBenefitShares}</div>
              }}
            />
            <Column
              title="判定理由"
              align="center"
              render={(record) => {
                return <div>{record.DetermineReason}</div>
              }}
            />
          </Table>  */}

          <Title className="title" title="高管信息"></Title>
         
          <List
            itemLayout="vertical"
            size="large"
            bordered
            dataSource={ggdata}
            renderItem={(item) => (
              <List.Item key={item.ManagementName} actions={[]}>
                <List.Item.Meta
                  avatar={<Avatar src={item.Avatar} />}
                  title={item.ManagementName}
                  description={'证书编号：'+item.PracticeNumber+'    职务：'+item.Position}
                />
                <Descriptions
                  bordered
                  column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                  style={{ margin: "10px" }}
                  className="table"
                >
                  {/* <Descriptions.Item label="是否有基金从业资格">
                    {item.ISPracticeQualified}
                  </Descriptions.Item>
                  <Descriptions.Item label="资格获取方式">
                    {item.QualifiedGetFile}
                  </Descriptions.Item> */}
                  <Descriptions.Item label="工作履历">
                    <Table
                      dataSource={item.works}
                      size="middle"
                      rowKey={(record) => record.ID}
                      pagination={false}
                    >
                      <Column
                        title="时间"
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.StartDate}</div>
                            </div>
                          )
                        }}
                      />
                      <Column
                        title="任职单位"
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.WorkCompany}</div>
                            </div>
                          )
                        }}
                      />
                      <Column
                        title="任职部门"
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.WorkDepartment}</div>
                            </div>
                          )
                        }}
                      />
                      <Column
                        title="职务"
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.Position}</div>
                            </div>
                          )
                        }}
                      />
                    </Table>
                  </Descriptions.Item>
                </Descriptions>
              </List.Item>
            )}
          />

          <Title className="title" title="获奖情况"></Title>

          <Table 
                      dataSource={tableData_award_detail}
                      loading={loading}
                      pagination={{
                        position: ["none", "bottomCenter"],
                      }}
                      size="middle"
                      rowKey={(record) => record.ID}
                    >
                      <Column
                        title="公司简称"
                        width={120}
                        align="center"
                        sorter={(a: any, b: any) => a.ComAbbrName.localeCompare(b.ComAbbrName)}
                        render={(record) => {
                          return (
                            <div>
                            {record.ComAbbrName}
                          </div>
                          )
                        }}
                      />

                      <Column
                        title="大赛全称"
                        width={220}
                        align="center"
                        sorter={(a: any, b: any) => a.FullName.localeCompare(b.FullName)}
                        render={(record) => {
                          return (
                            <div>
                            {record.FullName}
                          </div>
                          )
                        }}
                      />
                      {0 === this.state.awardTabIndex ? (
                      <Column
                        title="大赛简称"
                        width={130}
                        align="center"
                        sorter={(a: any, b: any) => a.AbbrName.localeCompare(b.AbbrName)}
                        render={(record) => {
                          return (
                            <div>
                            {record.AbbrName}
                          </div>
                          )
                        }}
                      />
                      ):null}
                      {0 === this.state.awardTabIndex ? (
                      <Column
                        title="排名周期"
                        width={130}
                        align="center"
                        sorter={(a: any, b: any) => a.RankCycle1.localeCompare(b.RankCycle1)}
                        render={(record) => {
                          return (
                            <div>
                            {record.RankCycle1}
                          </div>
                          )
                        }}
                      />):<Column
                      title="年期"
                      width={130}
                      align="center"
                      sorter={(a: any, b: any) => a.Period.localeCompare(b.Period)}
                      render={(record) => {
                        return (
                          <div>
                          {record.Period}
                        </div>
                        )
                      }}
                    />}
                    <Column
                        title="策略类型"
                        width={110}
                        align="center"
                        sorter={(a: any, b: any) => a.PolicyType.localeCompare(b.PolicyType)}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.PolicyType}</div>
                            </div>
                          )
                        }}
                      />
                      <Column
                        title="奖项"
                        width={110}
                        align="center"
                        sorter={(a: any, b: any) => a.Awards.localeCompare(b.Awards)}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.Awards}</div>
                            </div>
                          )
                        }}
                      />
                      {0 === this.state.awardTabIndex ? (
                        <Column
                        title="获奖产品名称"
                        width={150}
                        align="center"
                        sorter={(a: any, b: any) => a.AwardName.localeCompare(b.AwardName)}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.AwardName}</div>
                            </div>
                          )
                        }}
                      />):
                      <Column
                        title="公告日期"
                        width={110}
                        align="center"
                        sorter={(a, b) => new Date(a.PublishDate1).getTime() - new Date(b.PublishDate1).getTime()}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.PublishDate1}</div>
                            </div>
                          )
                        }}
                      />}
                      {0 === this.state.awardTabIndex ? (
                      <Column
                        title="获奖排名"
                        width={110}
                        align="center"
                        sorter={(a, b) => a.AwardsRank - b.AwardsRank}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.AwardsRank}</div>
                            </div>
                          )
                        }}
                      />
                      ):<Column
                      title="获奖经理人"
                      width={110}
                      align="center"
                      sorter={(a, b) => a.AwardWinManager - b.AwardWinManager}
                      render={(record) => {
                        return (
                          <div>
                            <div>{record.AwardWinManager}</div>
                          </div>
                        )
                      }}
                    />}
                      
                      
                    </Table>

        </Spin>
      </IndexCss>
    )
  }
  showModal() {
    console.log("showmdoal......")
    this.setIsModalVisible(true)
  }
  goVip() {
    this.goAsk(1)
  }
  goBusiness() {
    this.goAsk(2)
  }
  goAsk(ftype) {
    let url =
      "/group/askDetails/" +
      api.encode(this.state.guid) +
      "/" +
      api.encode(ftype)
    window.open(url, "_blank")
  }
  handleOkModal() {
    let _this = this

    let str = api.convertNull(_this.state.txtKard)
    if (str != "") {
      confirm({
        title: "您确定要提交吗?",
        icon: <ExclamationCircleOutlined />,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          api
            .saveCorpGroupCard(
              _this.state.corp.CompanyCode,
              _this.state.corp.AbbrName,
              str
            )
            .then((res) => {
              if (res && res.valid) {
                message.success("提交成功！")
                _this.setIsModalVisible(false)
              } else {
                message.error("提交失败，请稍候重试！")
              }
            })
        },
        onCancel() {
          console.log("Cancel")
        },
      })
    } else {
      message.error("请填写内容")
    }
  }
  handleCancelModal() {
    this.setIsModalVisible(false)
  }
  setIsModalVisible(isShow) {
    console.log("isShow==", isShow)
    this.setState({
      isModalVisible: isShow,
    })
  }
  handelKardChange(e) {
    this.setState({
      txtKard: e.target.value,
    })
  }
}

export default GroupDetails
