import React from "react"
import "./style/index.scss"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import {
  Spin,
  Input,
  Select,
  Table,
  Pagination,
  Tabs,
  DatePicker,
  message,
  Button,
  Form,
  Row,
  Col,
} from "antd"

import api from "../../util/api"
import config from "../../util/config"
import moment from "moment"

const { Column } = Table

const data = [
  {
    date: {
      time: "2021-12-01",
      desc: "星期三",
    },
    events: "2021首届华南广州智能装备大会",
    provenance: "东方财富",
    host: "中信证券",
    type: "峰会",
    influencePlate: "工业4.0",
    followStock: ["宁德时代", "比亚迪"],
  },
]

class Events extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      tableMonth: ["上月", "本月", "下月"],
      tebleMonthIndex: 1,
      qryDateS: "",
      qryDateE: "",
      qryText:"",
      qryBankuai:"",

      pageIndex: 1,
      pageSize: 10,
      tableData: [],
      allTableData:[],
      total:0
    }
  }

  componentDidMount() {
    if (api.checkLogin()) {
      this.getEventListByMonthIndex(this.state.tebleMonthIndex)
    } else {
      message.error("请登陆")
      this.props.history.push("/")
    }
  }
  getEventListByMonthIndex(tebleMonthIndex) {
    let dateS = this.getDateSByMonth(tebleMonthIndex)
    let dateE = this.getDateE(dateS)
    this.setState({
      qryDateS: dateS,
      qryDateE: dateE,
    })
    this.setFirstPageIndex()
    this.getEventList('',dateS, dateE, 1, 10,'')
  }
  getByBankuai(bankuai){
    this.setState(
      {
        qryText: "",
        qryBankuai:bankuai,
        pageIndex: 1,
      },
      () => {
        this.getEventList('',this.state.qryDateS, this.state.qryDateE, 1, 10,bankuai)
      }
    )
    
  }
  getEventList(qryText,dateS, dateE, pageIndex, pageSize,bankuai) {
    console.log("qry datas=", dateS)
    console.log("qry dataE=", dateE)
    console.log("pageIndex=", pageIndex)
    console.log("pageSize=", pageSize)
    this.setState({
      loading: true
    })

    api.getEventList(qryText,pageIndex, pageSize, dateS, dateE,bankuai).then((res) => {
      if (res) {
        console.log('event res==',res)
        let tableData = res && res.Table ? res.Table : []
        let allTableData = res && res.Table ? res.Table : []
        let total = res && res.Table && res.Table.length>0 ? res.Table[0].ALLCOUNT : []
        this.setState({
          tableData: tableData,
          allTableData:allTableData,
          loading:false,
          total:total
        })
        console.log('total===',total)
        console.log('allTableData.length==',allTableData.length)
      }
    })
  }

  changeInput(changeInput){
    this.setState({
      qryText:event.target.value
    })
  }

  reload(){
    this.getByBankuai('')
  }
  onKeyupRes(e){
    if(e.keyCode === 13) {
       this.doQuery()
     }
  }

  doQuery(){
    this.pageChange(1,10)
    //let qryValue = this.refs.txtQryEvent.value
    // console.log('qryValue===',qryValue)
    // let allTableData = this.state.allTableData
    // let tableData = this.state.tableData
    // console.log('allTableData.length==',allTableData.length)
    // if (qryValue && qryValue!=''){
    //   tableData = allTableData.filter(item=>{
    //      return item.TITLE.indexOf(qryValue)>-1 || item.SOURCE.indexOf(qryValue)>-1 || item.HOSTUNIT.indexOf(qryValue)>-1
    //      || item.STOCKS.indexOf(qryValue)>-1
    //   })
    // }else{
    //    tableData = allTableData
        
    // }
    // console.log('tableData.length==',tableData.length)
    // this.setState({
    //   tableData: tableData,
    //   total:tableData.length
    // })
    // console.log('qryValue==',qryValue)

  }

  render() {
    const { loading, tableMonth, tebleMonthIndex, tableData,total,pageSize,qryText } = this.state

    return (
      <div className="events">
        <Spin spinning={loading}>
          <div className="content">
            <div className="content_bg">
              <div className="content_box">
                {/* 事件搜索 */}
                {/* <div className="eventsSearch">
                  <div className="name">事件搜索</div>
                  <section className="ipt">
                    <p>
                      <input  ref="txtQryEvent"/>
                    </p>
                     <p onClick={() => this.doQuery()}></p> 
                  </section>
                </div> */}
                <div className="search">
                        <Row gutter={24}>
                          <Col span={8} key={1} offset={8}>
                          <Input  ref="txtQryEvent" onChange={this.changeInput.bind(this)} placeholder="事件搜索" size="large" onKeyUp={this.onKeyupRes.bind(this)}/>
                          </Col>
                          <Col span={6} key={4}>
                            <Button type="primary" onClick={() => this.doQuery()}>
                              搜索
                            </Button>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                              type="primary"
                              onClick={() => this.reload()}
                            >
                              刷新
                            </Button>
                          </Col>
                        </Row>
                </div>
                {/* 板块 */}
                <div className="plate" style={{display:'none'}}>
                  <div className="plate_item red" >
                    <div className="item_title">
                      <div className="left">充电桩</div>
                      <div className="right">+1.71%</div>
                    </div>
                    <div className="item_content">
                      速度加快进度数据电视剧集电视剧
                    </div>
                    <div className="item_stock_list">
                      <div className="stock_item">
                        通河科技 <span>+1.12%</span>
                      </div>
                      <div className="stock_item">
                        通河科技 <span>+1.12%</span>
                      </div>
                    </div>
                  </div>
                  <div className="plate_item green" >
                    <div className="item_title">
                      <div className="left">充电桩</div>
                      <div className="right">+1.71%</div>
                    </div>
                    <div className="item_content">
                      速度加快进度数据电视剧集电视剧
                    </div>
                    <div className="item_stock_list">
                      <div className="stock_item">
                        通河科技 <span>+1.12%</span>
                      </div>
                      <div className="stock_item">
                        通河科技 <span>+1.12%</span>
                      </div>
                    </div>
                  </div>
                  <div className="plate_item grey" >
                    <div className="item_title">
                      <div className="left">充电桩</div>
                      <div className="right">+1.71%</div>
                    </div>
                    <div className="item_content">
                      速度加快进度数据电视剧集电视剧
                    </div>
                    <div className="item_stock_list">
                      <div className="stock_item">
                        通河科技 <span>+1.12%</span>
                      </div>
                      <div className="stock_item">
                        通河科技 <span>+1.12%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                {/* 表格筛选 */}
                <div className="tableFliter">
                  <div className="left">
                    {tableMonth.map((item, index) => {
                      return (
                        <div
                          className={
                            index === tebleMonthIndex
                              ? "active monthItem"
                              : "monthItem"
                          }
                          onClick={() => this.monthChange(index)}
                        >
                          {item}
                        </div>
                      )
                    })}
                  </div>
                  <div className="right">
                    <DatePicker
                      locale={locale}
                      className="timeChange"
                      onChange={this.onChangeMonth.bind(this)}
                      placeholder="请选择时间"
                      format={"YYYY年MM月DD日"}
                    />
                  </div>
                </div>
                {/* 表格内容 */}
                <div className="tableBox">
                  <Table
                    dataSource={tableData}
                    loading={loading}
                    pagination={{
                      position: ["none", "bottomCenter"],
                      onChange: this.pageChange.bind(this),
                      total:total,
                      pageSize:pageSize
                    }}
                    size="middle"
                    rowKey={(record) => record.ID}
                  >
                    <Column
                      title="日期"
                      render={(record) => {
                        return (
                          <div>
                            <div className="time">{record.CREATETIME}</div>
                            <div className="desc">{record.MEETINGEDATE}</div>
                          </div>
                        )
                      }}
                    />
                    <Column
                      title="标题内容"
                      dataIndex="TITLE"
                      render={(TITLE) => {
                        return <div
                        className="innerhtml"
                        dangerouslySetInnerHTML={{
                          __html: api.qryhightlight(TITLE,qryText),
                        }}
                      ></div>
                      }}
                    />
                    <Column
                      title="出处"
                      dataIndex="SOURCE"
                      render={(SOURCE) => {
                        return <div>{SOURCE}</div>
                      }}
                    />
                    {/* <Column
                      title="主办单位"
                      dataIndex="HOSTUNIT"
                      render={(HOSTUNIT) => {
                        return <div>{HOSTUNIT}</div>
                      }}
                    />
                    <Column
                      title="类型"
                      dataIndex="NRTYPE"
                      render={(NRTYPE) => {
                        return <div>{NRTYPE}</div>
                      }}
                    /> */}
                    <Column
                      title="行业/板块"
                      dataIndex="BANKUAINAME"
                      render={(BANKUAINAME) => {
                        return (
                          <div
                            onClick={() => this.getByBankuai(BANKUAINAME)}
                            className="link"
                          >
                            {BANKUAINAME}
                          </div>
                        )
                      }}
                    />
                    <Column
                      title="个股"
                      dataIndex="STOCKS"
                      render={(STOCKS) => {
                        return <div>{STOCKS}</div>
                      }}
                    />
                    <Column
                      title="热度"
                      dataIndex="REDU"
                      render={(REDU) => {
                        return <div>{REDU}</div>
                      }}
                    />
                    {/* <Column
                      title="影响"
                      dataIndex="INFLUENCE"
                      render={(INFLUENCE) => {
                        //return followStock.map((item) => {
                        return <div>{INFLUENCE}</div>
                        //})
                      }}
                    /> */}
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    )
  }
  setFirstPageIndex() {
    this.setState({
      pageIndex: 1,
      pageSize: 10,
    })
  }
  onChangeMonth(date) {
    console.log('date--',date)
    let dateS = moment(date).format("yyyy-MM-DD")
    //let dateE = this.getDateE(dateS)
    this.setFirstPageIndex()

    this.getEventList('',dateS, dateS, 1, 10,'')
  }
  //得到月初
  getDateSByMonth(tebleMonthIndex) {
    let dMonth = tebleMonthIndex - 1
    console.log("dMonth===", dMonth)
    let dateS =
      dMonth < 0
        ? moment().subtract(1, "months").format("YYYY-MM") + "-01"
        : moment().add(dMonth, "months").format("YYYY-MM") + "-01"
    console.log("dateS====", dateS)
    return dateS
  }
  getDateE(dateS) {
    return moment(moment(dateS).add(1, "months"))
      .subtract(1, "days")
      .format("YYYY-MM-DD")
  }
  monthChange(index) {
    this.setState({
      tebleMonthIndex: index,
    })

    this.getEventListByMonthIndex(index)
  }
  pageChange(page, pageSize) {
    this.setState({
      pageIndex: page,
      pageSize: pageSize,
    })
    let dateS = this.state.qryDateS
    let dateE = this.state.qryDateE
    let qryText = this.state.qryText// this.refs.txtQryEvent.value
    let qryBankuai = this.state.qryBankuai
    this.getEventList(qryText,dateS, dateE, page, pageSize,qryBankuai)
    console.log("page", page)
    console.log("pageSize", pageSize)
  }
}

export default Events
