import React from "react"
import styled from "styled-components"
import { NavLink, Link } from "react-router-dom"
import { Spin, message, Divider,Row,Col,Alert,Button,Typography,Card } from "antd"
import { ImportOutlined,ShoppingCartOutlined } from "@ant-design/icons"
import user from "../../assets/imgs/report/user.png"
import pdf from "../../assets/imgs/report/pdf.png"
import { reportList } from "./index"
import api from "../../util/api"
import { throwStatement } from "@babel/types"
import config from "../../util/config"


const { Text } = Typography;

const Box = styled.div`
  background: #f4f5f7;
  min-height: 100vh;
  font-size: 17px;
  margin-bottom: 27px;

  .orderImgClass {
    width:200px;
    height:200px;
  }
  .orderFont{
    color: #e26507;
    font-size:16px;
    font-weight: bold;    
  }
  .top {
    background: #f4f5f7;
    border-bottom: 1px solid #cdced0;
    position: sticky;
    top: 68px;
    left: 0;
    z-index: 100;
  }
  .text-muted {
    color: #6c757d !important;
    font-size:14px;
  }
  .text-price {
    font-size:21px;
    font-weight: bold;    
  }
  .contarin {
    width: 1160px;
    margin: 0 auto;
    height: 56px;
    ul {
      padding: 0 60px;
      display: flex;
      align-items: center;
      li {
        line-height: 56px;
        margin-right: 81px;
        cursor: pointer;
        font-size: 17px;
        color: #585654;
        position: relative;
        &.active {
          color: #3c81ce;
          &:after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: #1769c5;
          }
        }
      }
    }
  }
  .text-content {
    display: flex;
    min-height: 90vh;
    margin-top: 22px;
    img {
      max-width: 100%;
    }
    .text {
      padding: 30px 35px;
      flex: 1;
      font-size: 18px;
      background: #fff;
      .title {
        // width: 584px;
        font-size: 24px;
        //font-weight: 400;
        line-height: 42px;
        margin-bottom: 10px;
      }
      .tag {
        display: flex;
        align-items: center;
        justify-content: flex-start; //space-between;
        color: #929598;
        font-size: 14px;
        color: rgb(153, 153, 153);
        overflow: hidden;
        .resource {
          display: flex;
          align-items: center;
          section {
            margin-left: 20px;
            width: 110px;
            line-height: 21px;
            text-align: center;
            font-size: 14px;
            color: #387bf6;
            border: 1px solid #387bf6;
            border-radius: 4px;
          }
        }
        .createUser {
          margin-left: 20px;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        .stock {
          margin-left: 20px;
        }
        // &:after {
        //   content: "";
        //   width: 90px;
        // }
        .time {
          font-size: 14px;
          color: #929598;
          margin-left: auto;
        }
      }

      .pdf {
        display: flex;
        font-size: 14px;
        align-items: center;
        color: #3c81ce;
        margin-bottom: 1px;
        margin-top: 20px;
        img {
          margin-right: 4px;
        }
      }
      .innerhtml {
        padding: 1px 0;
        font-size: 16px;
        //font-weight: 400;
        color: rgb(51, 51, 51);
        line-height: 36px;
      }
      .ipyGSt {
        font-size: 20px;
        color: rgb(51, 51, 51);
        line-height: 36px;
        margin-top: 1px;
        margin-buttom: 2px;
        padding-left: 1px;
        font-weight: bold;
        position: relative;
      }
    }
    .recommand {
      width: 381px;
      margin-left: 20px;
      .item {
        margin-bottom: 21px;
        background: #fff;
        padding: 0 27px;
        .item_label {
          line-height: 75px;
          border-bottom: 1px solid #e9e9e9;
          font-size: 20px;
          font-weight: bold;
        }
      }
      .item_list {
        padding: 26px 0;
        font-size: 16px;
        font-weight: 400;
        color: #585654;
        line-height: 30px;
        display: flex;
        flex-wrap: wrap;
        p {
          width: calc(100% / 3);
          &:nth-of-type(3n - 1) {
            text-align: center;
          }
          &:nth-of-type(3n) {
            text-align: right;
          }
          &.active {
            color: #3c81ce;
            &:after {
              content: " ";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background: #1769c5;
            }
          }
        }
      }
      .item_column {
        padding: 26px 0;
        font-size: 17px;
        font-weight: 300;
        line-height: 22px;
        p {
          margin-bottom: 29px;
          overflow: hidden;
          display: -webkit-box; //将元素设为盒子伸缩模型显示
          -webkit-box-orient: vertical; //伸缩方向设为垂直方向
          -webkit-line-clamp: 2; //超出3行隐藏，并显示省略号
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`

class PaidInfoDetail extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      idx: 1,
      loading: true,
      orderImg:"",
      report: {},
      moreList: [],
      currentGuid: "",
      currentMenuId: "",
      menuList: [], //this.props.menuList,
      tabList: [
      ],
    }
  }
  componentDidMount() {
    let guid = this.props.match.params.id 
    if (api.checkLogin()) {
      this.getPaidInfoDetail(guid)      
    } else {
      message.error("请登陆")
      this.props.history.push("/")
    }
  }
  changeMenu(clickMenuId, index) {
  }
  goDetail(guid) {
    this.getPaidInfoDetail(guid)
  }
  getPaidInfoDetail(guid) {
    console.log("guid==", guid)
    this.setState({
      loading: true,
      currentGuid: guid,
    })
    api
      .getPaidInfoDetail(guid)
      .then((res) => {
        console.log("res===", res)
        let report =
          res && res.Table && res.Table.length > 0 ? res.Table[0] : {}
        report.SUMMARY = api.convertNull(report.SUMMARY)
        this.setState({
          report: report,
          currentMenuId: report.FTYPE,
          loading: false,
        })
      })
      .catch((error) => {
        setTimeout(() => {
          this.setState({
            loading: false,
          })
        }, 1000)
      })
  }
  changeTab(v) {
    this.setState({
      idx: v,
      loading: true,
    })
    setTimeout(() => {
      this.setState({
        loading: false,
      })
    }, 1000)
  }
  openPdf() {
    let pdf = this.state.report.FILEPATH
    console.log("pdf==", pdf)
    window.open(pdf, "_blank")
  }
  doBuy(){
    api
      .getPaidOrder(this.state.currentGuid)
      .then((res) => {
        console.log('res 1111==',res)
        this.setState({
          loading: false,
       })
       //let result = JSON.parse(res)
        if (res && res.IsSuccess){
            let codeUrl = 'data:image/gif;base64,' + res.ReturnValue
            console.log('codeUrl==',codeUrl)
            this.setState({
              orderImg: codeUrl,
           })
        }else
          alert('获取订单信息失败！')
        
      })
      .catch((error) => {
        setTimeout(() => {
          this.setState({
            loading: false,
          })
        }, 1000)
      })
  }
  render() {
    const { tabList, idx, loading, report, moreList, menuList, currentMenuId,qryText ,orderImg} =
      this.state
    return (
      <Box>
        <Spin spinning={loading}>
          {/* <div className="top">
            <div className="contarin">
              <ul>
                {tabList.map((t) => {
                  return (
                    <li
                      key={t.value}
                      className={idx === t.value ? "active" : ""}
                      onClick={() => this.changeTab(t.value)}
                    >
                      {t.label}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div> */}
          <div className="text-content maxContrain">
            <div className="text">
              <p className="title">
                {report.TITLE}
              </p>
              <div className="tag">
                <p className="resource">
                  <span>{report.FSOURCE}</span>
                  {/* <section>{report.REPORTTYPENAME}</section> */}
                </p>
               

                <p className="time">{report.CREATETIME}</p>
                {/* <p className="stock">个股：{report.STOCKNAME}</p>
                <p className="level">评级：{report.INVESTNAME}</p> */}
              </div>
              {/* <p className="time">{report.CREATEDATE1}</p> */}
              
              <Divider orientation="left" plain>
                
              </Divider>

              <div
                className="innerhtml"
                dangerouslySetInnerHTML={{
                  __html:report.SUMMARY,
                }}
              ></div>
              <Divider orientation="left" plain>
                <div>
                </div>
              </Divider>
              <p className="pdf">
                <img src={pdf} alt="" />附件：
                {report.FILEPATH && report.FILEPATH.indexOf('http')>=0 ? (
                  <a href={report.FILEPATH} target="_blank">{report.FILENAME}</a>
                ) : (
                  <span>{report.FILENAME}</span>
                )}
              </p>
              <div style={{height:'30px'}}></div>
              {report.PRIGHT ==0 ? (
                <Card>
                  <Row gutter={20} justify="start">
                    <Col span={6}> <Text type="danger" className="text-price" >{report.PRICE+' 元'}</Text>
                    <br/><span className="text-muted">线上付款</span>
                    </Col>
                    
                    <Col span={10}>
                    {orderImg=="" ? (
                       <Button type="danger" icon={<ShoppingCartOutlined />} onClick={() => this.doBuy()}>点击购买</Button>
                       ) : (
                        <span className="orderFont">请用微信扫码付款，付款成功后刷新页面即可：</span>
                        )}
                     </Col>
                     <Col span={6}>
                      {orderImg=="" ? (
                        null
                        ) : (
                          <img src={orderImg} alt="" className="orderImgClass"/>
                          )}
                     </Col>
                    </Row>
                    </Card>
                    ) : (
                      null
                      )}

                      

            </div>
           
          </div>
        </Spin>
      </Box>
    )
  }
}
export default PaidInfoDetail
