import React from "react"
import { NavLink, Link } from "react-router-dom"
import styled from "styled-components"

import {
  message,
  Spin,
  Radio,
  Pagination,
  DatePicker,
  Space,
  Form,
  Input,
  Col,
  Row,
  Switch,
  Select,
  Button,
  Dropdown, 
} from "antd"
import {
  CaretUpOutlined,
  CaretDownOutlined,
  ConsoleSqlOutlined,
  DownOutlined,
} from "@ant-design/icons"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import config from "../../util/config"


const Box = styled.div`
  background: #f4f5f7;
  min-height: 100vh;
  
`


class Bbs extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      url:'http://bbs.huijuzx.com:81/',
      iFrameHeight:'600px'
    }
  }
  componentDidMount() {
  }

  render() {
    const {
      url
    } = this.state
    return (
      <div>
      <iframe  ref="iframe" scrolling="yes" frameBorder="0"
          style={{width:'100%',height:this.state.iFrameHeight, overflow:'visible'}}
          onLoad={() => {//iframe高度不超过content的高度即可
              let h = document.documentElement.clientHeight - 20;
              this.setState({
                  "iFrameHeight": h + 'px'
              });
          }} 
          src={url}
      />
  </div>
    )
  }
}
export default Bbs
