import React from "react"
import moment from "moment"
import "./style/index.scss"
import {
  Spin,
  Input,
  Select,
  Table,
  Pagination,
  Tabs,
  DatePicker,
  message,
  AutoComplete,
  Popover,
  Button,
} from "antd"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import * as echarts from "echarts/lib/echarts"
import {
  barOption,
  pieOption,
  getIndustryHotBarOption,
  getIndustryHotPieOption,
} from "./js/chartOption"
import ReactECharts from "echarts-for-react"
import { GridComponent, LegendComponent } from "echarts/components"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"

import api from "../../util/api"
import config from "../../util/config"

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom"
echarts.use([GridComponent, LegendComponent])

const { Option } = Select
const { Search } = Input
const { TabPane } = Tabs
const { Column } = Table

const data = [
  {
    id: "1",
    name: "22222",
    monthIncome: 2222,
    yearIncome: 21212,
    totalIncome: 2121,
    age: 32,
    weekPriceLevel: 56,
    increaseOfTheDay: "12.22",
    increaseOfTheMonth: "12.332",
    transferState: "近12个月首推",
    recommend: 1,
  },
]

const content = ({ List }) => (
  <div className="">
    <p>Content</p>
    <p>Content</p>
  </div>
)

class GoldStock extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isMoreStock: false,
      loading: false,
      idx: 1,
      indusyHotData: [],
      barOption: {},
      pieOption: {},
      firstAvgFieldByMonth: 0,
      firstAvgFieldByYear: 0,
      industryDistribute: [],
      indusyHotText: "",
      tabList: [
        {
          label: "券商金股",
          value: 1,
        },
        {
          label: "基金金股",
          value: 2,
        },
        {
          label: "牛散金股",
          value: 3,
        },
      ],
      text: "券商金股",
      bigDataTab: ["多家推荐", "首次推荐", "行业集中"],
      bigDataTabIndex: 0,
      multipleList: [],
      firstList: [],
      subFirstList: [],
      industryList: [],
      tableData: [],
      allTableData: [],
      numberData: {},
      options: [],
    }
  }

  componentDidMount() {
    // const barChart = echarts.init(document.getElementById('barChart'))
    // barChart.setOption(barOption)
    if (api.checkLogin()) {
      this.getGoldStockNumber("")
    } else {
      message.error("请登陆")
      this.props.history.push("/")
    }
  }
  getWebGoldStockIndustryHot(month) {
    api.getWebGoldStockIndustryHot(month).then((res) => {
      console.log("getWebGoldStockIndustryHot res==", res)
      if (res && res.Table) {
        let pieData = []
        let barNames = []
        let barDatas = []
        let indusyHotText = ""
        res.Table.map((item) => {
          barNames.push(item.IndustryName)
          barDatas.push(item.IndustryHot) //item.RingRatio)
          pieData.push({
            name: item.IndustryName,
            value: item.IndustryHot,
          })
          indusyHotText = item.TjMonth
        })
        let barOption = getIndustryHotBarOption(barNames, barDatas)
        let pieOption = getIndustryHotPieOption(pieData)
        this.setState({
          barOption: barOption,
          pieOption: pieOption,
          indusyHotText: indusyHotText,
        })
      }
    })
  }
  getWebGoldStockHotSecu(month) {
    api.getWebGoldStockHotSecu(month).then((res) => {
      if (res && res.Table && res.Table.length > 0) {
        this.setState({
          firstAvgFieldByMonth: res.Table[0].ChangePCTM,
          firstAvgFieldByYear: res.Table[0].ChangePCTY,
        })
      }
    })
  }

  getWebGoldStockIndustryDistribute(month) {
    api.getWebGoldStockIndustryDistribute(month).then((res) => {
      if (res && res.Table && res.Table.length > 0) {
        this.setState({
          industryDistribute: res.Table,
        })
      }
    })
  }

  getGoldStockTotal(month) {
    api.getGoldStockTotal(month).then((res) => {
      console.log("total res==", res)
      let multipleList = [] //多家推荐
      let industryList = [] //按行业

      if (res.Table) {
        let firstList = res.Table.filter((t) => {
          return t.Number == 1
        })
        //首次推荐
        this.setState({
          firstList: firstList,
          subFirstList:
            firstList.length > 30 ? firstList.slice(0, 30) : firstList,
        })
        res.Table.map((t) => {
          //多家推荐
          if (t.Number > 3) {
            let obj = multipleList.find((item) => item.Number == t.Number)
            if (obj && obj.Number) {
              obj.List.push(t.StockName)
            } else {
              multipleList.push({
                Number: t.Number,
                List: [t.StockName],
              })
            }
          }
          //行业推荐
          let objIndustry = industryList.find(
            (item) => item.Industry == t.Industry
          )
          if (objIndustry && objIndustry.Industry) {
            objIndustry.List.push(t.StockName)
          } else {
            industryList.push({
              Industry: t.Industry,
              List: [t.StockName],
            })
          }
          industryList.sort((a, b) => {
            return b.List.length - a.List.length
          })
          console.log("industryList.length==", industryList.length)
          if (industryList.length > 0) {
            console.log("aaa==")
            industryList[0].List.sort((a, b) => {
              return a > b
            })
          }
          this.setState({
            industryList: industryList,
          })
        })
        this.setState({
          multipleList: multipleList,
        })
      }
    })
  }
  //组合
  getGoldStockCompose(month) {
    api.getGoldStockCompose(month).then((res) => {
      let tableData = res && res.Table ? res.Table : []
      let allTableData = res && res.Table ? res.Table : []
      this.setState({
        tableData: tableData,
        allTableData: allTableData,
      })
    })
  }

  //推荐次数排序
  getGoldStockNumber(month) {
    console.log("month=;;;;;", month)
    api.getGoldStockNumber(month).then((res) => {
      console.log("resssss=gold=", res)
      if (res && res.Table && res.Table.length > 0) {
        this.setState({
          numberData: res.Table[0],
        })
        let yearMonth = res.Table[0].TjMonth.substr(0, 7)
        console.log("yearMonth====", yearMonth)
        this.getGoldStockTotal(yearMonth)
        this.getGoldStockCompose(yearMonth)
        this.getWebGoldStockIndustryHot(yearMonth)
        this.getWebGoldStockHotSecu(yearMonth)
        this.getWebGoldStockIndustryDistribute(yearMonth)
        // this.getGoldStockDetail(yearMonth)
      }
    })
  }

  render() {
    const {
      loading,
      idx,
      tabList,
      text,
      bigDataTab,
      multipleList,
      firstList,
      subFirstList,
      industryList,
      numberData,
      tableData,
      options,
      isMoreStock,
      barOption,
      pieOption,
      firstAvgFieldByMonth,
      firstAvgFieldByYear,
      industryDistribute,
      indusyHotText,
    } = this.state

    return (
      <div className="goldStockPage">
        {/* 顶部tab */}
        <div className="top">
          <div className="contarin">
            <ul>
              {tabList.map((t, index) => {
                return (
                  <li
                    key={index}
                    className={idx === t.value ? "active" : ""}
                    onClick={() => this.changeTab(t.value)}
                  >
                    {t.label}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        {/* 内容部分  */}
        <Spin spinning={loading}>
          <div className="content">
            <div className="content_bg">
              <div className="content_box">
                {/* 券商金股大数据 */}
                <div className="goldStockBigData">
                  <div className="commonTitle">
                    <div className="leftName">{text}大数据</div>
                    <div className="rightFliter">
                      <DatePicker
                        locale={locale}
                        onChange={this.onChangeMonth.bind(this)}
                        placeholder="请选择时间"
                        format={"YYYY年MM月"}
                        picker="month"
                      />
                    </div>
                  </div>

                  <div className="BigDataTitle">
                    <div>{numberData.TjMonth1}数据</div>
                    <div>
                      推荐券商 <span>{numberData.SecuRecomNumber1}</span> 家
                    </div>
                    <div>
                      推荐次数 <span>{numberData.SecuRecomNumber2}</span> 次
                    </div>
                    <div>
                      推荐股票 <span>{numberData.SecuRecomStockNumber}</span> 支
                    </div>
                  </div>
                  {/* -- */}
                  <div className="changeTab">
                    {bigDataTab.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            index === this.state.bigDataTabIndex
                              ? "active"
                              : null
                          }
                          onClick={() => this.changeBigDataTab(index)}
                        >
                          {item}
                        </div>
                      )
                    })}
                  </div>
                  {/* 多家推荐 */}
                  {this.state.bigDataTabIndex === 0
                    ? multipleList.map((item, index) => {
                        return (
                          <div className="moreRecommend" key={index}>
                            <div className="moreRecommendItem">
                              <div className="left">
                                <span>{item.Number}</span>家券商同时推荐
                                <span>{item.List.length}</span>
                                支股票
                              </div>
                              <div className="right">
                                {item.List.map((t, i) => {
                                  return <span>{t}</span>
                                })}

                                {/* <span>深信服</span>
                                <span>药名康德</span>
                                <span>美畅股份</span>
                                <span>深信服</span>
                                <span>药名康德</span>
                                <span>美畅股份</span>
                                <span>深信服</span>
                                <span>药名康德</span>
                                <span>美畅股份</span>
                                <span>深信服</span> */}
                              </div>
                            </div>
                          </div>
                        )
                      })
                    : null}
                  {/* 首次推荐 */}
                  {this.state.bigDataTabIndex === 1 ? (
                    <div className="goldStockDetails">
                      <div className="biaodi">
                        <div>
                          <span>{firstList.length}</span>
                          <span className="biaodiDesc"></span>只股票
                        </div>
                        <div className="descTitle">标的数量</div>
                      </div>
                      <div className="zhangfu">
                        <div>
                          <span>{firstAvgFieldByMonth}%</span>
                        </div>
                        <div className="descTitle">当月均涨幅</div>
                      </div>
                      <div className="yearZhangfu">
                        <div>
                          <span>{firstAvgFieldByYear}%</span>
                        </div>
                        <div className="descTitle">年初至今涨幅</div>
                      </div>
                      <div
                        className="stockList"
                        onClick={this.showMoreStock.bind(this)}
                      >
                        {subFirstList.map((first) => {
                          return <span>{first.StockName}</span>
                        })}

                        <span>更多</span>
                        {isMoreStock ? <UpOutlined /> : <DownOutlined />}

                        {/* <span>深信服</span>
                        <span>药名康德</span> */}
                      </div>
                    </div>
                  ) : null}
                  {/* 行业集中 */}
                  {this.state.bigDataTabIndex === 2 ? (
                    industryDistribute.length == 0 ? null : (
                      <div className="goldStockDetails">
                        <div className="biaodi">
                          <div>
                            <span>{industryDistribute[0].IndustryNumber}</span>
                            只股票
                          </div>
                          <div className="descTitle">标的数量</div>
                        </div>
                        <div className="zhangfu">
                          <div>
                            <span>{industryDistribute[0].ChangePCTM}%</span>
                          </div>
                          <div className="descTitle">当月均涨幅</div>
                        </div>
                        <div className="hangye">
                          <div>
                            <span>{industryDistribute[0].IndustryName}</span>
                          </div>
                          <div className="descTitle">行业</div>
                        </div>
                        <div className="stockList">
                          {industryDistribute.map((t) => {
                            return <span>{t.stockName}</span>
                          })}
                          {/* <span>深信服</span>
                            <span>药名康德</span> */}
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
                {/* 券商金股组合 */}
                <div className="brokerageAndGoldStock">
                  <div className="commonTitle">
                    <div className="left">{text}组合</div>
                    <div className="right">
                      <DatePicker
                        locale={locale}
                        className="chooseMonth2"
                        onChange={this.onChangeMonth2.bind(this)}
                        placeholder="请选择时间"
                        format={"YYYY年MM月"}
                        picker="month"
                      />
                      <AutoComplete
                        // dropdownMatchSelectWidth={252}
                        style={{ width: 300 }}
                        options={options}
                        onSelect={this.onSelect.bind(this)}
                        onSearch={this.onSearch.bind(this)}
                      >
                        <Input.Search
                          size="large"
                          placeholder="请输入组合名称"
                          enterButton
                        />
                      </AutoComplete>
                      {/* <Search
                        className="chooseSearch"
                        placeholder="券商组合名称"
                        onSearch={this.onSearch.bind(this)}
                        enterButton
                      /> */}
                    </div>
                  </div>
                  {/* -table- */}
                  <Table
                    dataSource={tableData}
                    pagination={{
                      position: ["none", "bottomCenter"],
                      onChange: this.pageChange,
                    }}
                    rowKey={(record) => record.ID}
                  >
                    <Column
                      title="组合名称"
                      dataIndex="ComName"
                      render={(ComName) => {
                        return (
                          <div className="combinationName fontSize16">
                            {ComName}
                          </div>
                        )
                      }}
                    />
                    <Column
                      title="本月收益"
                      dataIndex="ProfitByMonth"
                      render={(ProfitByMonth) => {
                        return (
                          <div className="fontSize16">
                            {ProfitByMonth > 0 ? (
                              <div className="redFont">+{ProfitByMonth}%</div>
                            ) : null}
                            {ProfitByMonth < 0 ? (
                              <div className="greenFont">{ProfitByMonth}%</div>
                            ) : null}
                            {ProfitByMonth == 0 ? (
                              <div className="greyFont">{ProfitByMonth}%</div>
                            ) : null}
                          </div>
                        )
                      }}
                    />
                    <Column
                      title="今年收益"
                      dataIndex="ProfitByYear"
                      render={(ProfitByYear) => {
                        return (
                          <div className="fontSize16">
                            {ProfitByYear > 0 ? (
                              <div className="redFont">+{ProfitByYear}%</div>
                            ) : null}
                            {ProfitByYear < 0 ? (
                              <div className="greenFont">{ProfitByYear}%</div>
                            ) : null}
                            {ProfitByYear == 0 ? (
                              <div className="greyFont">{ProfitByYear}%</div>
                            ) : null}
                          </div>
                        )
                      }}
                    />
                    <Column
                      title="累计收益"
                      dataIndex="ProfitByAccu"
                      render={(ProfitByAccu) => {
                        return (
                          <div className="fontSize16">
                            {ProfitByAccu > 0 ? (
                              <div className="redFont">+{ProfitByAccu}%</div>
                            ) : null}
                            {ProfitByAccu < 0 ? (
                              <div className="greenFont">{ProfitByAccu}%</div>
                            ) : null}
                            {ProfitByAccu == 0 ? (
                              <div className="greyFont">{ProfitByAccu}%</div>
                            ) : null}
                          </div>
                        )
                      }}
                    />
                    <Column
                      title="操作"
                      dataIndex="operate"
                      render={(text, row) => {
                        return (
                          <div className="lookMoreDetails fontSize16">
                            <Link
                              to={`/goldStock/details/${api.encode(row.ID)}`}
                            >
                              查看详情
                            </Link>
                          </div>
                        )
                      }}
                    />
                  </Table>
                </div>

                {/* 券商金股行业统计 */}
                <div className="brokerageIndustryStatistics">
                  <div className="commonTitle">
                    <div className="left">
                      券商金股行业统计
                      <span>|&nbsp;&nbsp;&nbsp;{indusyHotText}</span>
                    </div>
                    <div className="right">
                      <DatePicker
                        locale={locale}
                        onChange={this.onChangeMonth_industryHot.bind(this)}
                        placeholder="请选择时间"
                        format={"YYYY年MM月"}
                        picker="month"
                      />
                    </div>
                  </div>
                  {/* -- */}
                </div>

                {/* 行业热度轮动 + 金股行业分布 + 券商金股大数据 */}
                <div className="threeArea">
                  {/* 行业热度轮动 */}
                  <div className="industryHot">
                    <div className="industryHotTitle">
                      <span className="name1">行业热度轮动</span>
                      <span className="name2">较上个月环比</span>
                      <div className="downArrows">↓</div>
                      <div className="upArrows">↑</div>
                    </div>

                    <div className="nameAndHot">
                      <div className="name">
                        <div>行业名称</div>
                      </div>
                      <div className="hot">
                        <div>行业热度</div>
                      </div>
                    </div>
                    {/* echarts 柱状图 */}
                    <ReactECharts option={barOption} />
                  </div>
                  {/* 金股行业分布 */}
                  <div className="industryDistributed">
                    <div className="industryDistributedTitle">
                      <span className="name1">金股行业分布</span>
                      <span className="name2">申万一级行业</span>
                    </div>
                    {/* echarts 饼状图 */}
                    <ReactECharts
                      style={{ height: "300px" }}
                      option={pieOption}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    )
  }

  showMoreStock() {
    let isMoreStock = !this.state.isMoreStock
    let firstList = this.state.firstList
    this.setState({
      isMoreStock: isMoreStock,
      subFirstList: isMoreStock
        ? firstList
        : firstList.length > 30
        ? firstList.slice(0, 30)
        : firstList,
    })
  }

  changeTab(value) {
    this.setState({
      idx: value,
      loading: true,
      text: this.state.tabList.find((item) => item.value == value).label,
    })
    setTimeout(() => {
      this.setState({
        loading: false,
      })
    }, 1000)
  }

  pageChange(page, pageSize) {
    console.log("page", page)
    console.log("pageSize", pageSize)
  }
  getBarOption() {
    // 调用接口
    return barOption
  }
  getPieOption() {
    // 调用接口
    return pieOption
  }
  onChangeMonth(date) {
    this.getGoldStockNumber(moment(date).format("yyyy-MM"))
  }
  onChangeMonth2(date) {
    this.getGoldStockCompose(moment(date).format("yyyy-MM"))
  }
  onChangeMonth_industryHot(date) {
    this.getWebGoldStockIndustryHot(moment(date).format("yyyy-MM"))
  }
  onSearch(value) {
    console.log("value==", value)
    console.log("this.state.tableData==", this.state.tableData)
    let tableData = this.state.allTableData.filter((item) => {
      return item.ComName.indexOf(value) > -1
    })
    let options = []
    tableData.map((item) => {
      options.push({
        value: item.ComName,
        label: item.ComName,
      })
    })
    console.log("options===", options)
    this.setState({
      options: options,
      // tableData: tableData,
    })
  }
  onSelect(value) {
    let tableData = this.state.allTableData.filter((item) => {
      return item.ComName == value
    })
    this.setState({
      tableData: tableData,
    })
  }
  changeBigDataTab(index) {
    this.setState({
      bigDataTabIndex: index,
    })
  }
}

export default GoldStock
