import React from "react"
import styled from "styled-components"
import { NavLink, Link } from "react-router-dom"
import { Spin, message, Divider } from "antd"
import { ImportOutlined } from "@ant-design/icons"
import user from "../../assets/imgs/report/user.png"
import pdf from "../../assets/imgs/report/pdf.png"
import { reportList } from "./index"
import api from "../../util/api"
import { throwStatement } from "@babel/types"
import config from "../../util/config"

const Box = styled.div`
  background: #f4f5f7;
  min-height: 100vh;
  font-size: 17px;
  margin-bottom: 27px;
  .top {
    background: #f4f5f7;
    border-bottom: 1px solid #cdced0;
    position: sticky;
    top: 68px;
    left: 0;
    z-index: 100;
  }
  .contarin {
    width: 1160px;
    margin: 0 auto;
    height: 56px;
    ul {
      padding: 0 60px;
      display: flex;
      align-items: center;
      li {
        line-height: 56px;
        margin-right: 81px;
        cursor: pointer;
        font-size: 17px;
        color: #585654;
        position: relative;
        &.active {
          color: #3c81ce;
          &:after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: #1769c5;
          }
        }
      }
    }
  }
  .text-content {
    display: flex;
    min-height: 90vh;
    margin-top: 22px;
    img {
      max-width: 100%;
    }
    .text {
      padding: 30px 35px;
      flex: 1;
      font-size: 18px;
      background: #fff;
      .title {
        // width: 584px;
        font-size: 24px;
        //font-weight: 400;
        line-height: 42px;
        margin-bottom: 10px;
      }
      .tag {
        display: flex;
        align-items: center;
        justify-content: flex-start; //space-between;
        color: #929598;
        font-size: 14px;
        color: rgb(153, 153, 153);
        overflow: hidden;
        .resource {
          display: flex;
          align-items: center;
          section {
            margin-left: 20px;
            width: 110px;
            line-height: 21px;
            text-align: center;
            font-size: 14px;
            color: #387bf6;
            border: 1px solid #387bf6;
            border-radius: 4px;
          }
        }
        .createUser {
          margin-left: 20px;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        .stock {
          margin-left: 20px;
        }
        // &:after {
        //   content: "";
        //   width: 90px;
        // }
        .time {
          font-size: 14px;
          color: #929598;
          margin-left: auto;
        }
      }

      .pdf {
        display: flex;
        font-size: 14px;
        align-items: center;
        color: #3c81ce;
        margin-bottom: 1px;
        margin-top: 20px;
        img {
          margin-right: 4px;
        }
      }
      .innerhtml {
        padding: 1px 0;
        font-size: 16px;
        //font-weight: 400;
        color: rgb(51, 51, 51);
        line-height: 36px;
      }
      .ipyGSt {
        font-size: 20px;
        color: rgb(51, 51, 51);
        line-height: 36px;
        margin-top: 1px;
        margin-buttom: 2px;
        padding-left: 1px;
        font-weight: bold;
        position: relative;
      }
    }
    .recommand {
      width: 381px;
      margin-left: 20px;
      .item {
        margin-bottom: 21px;
        background: #fff;
        padding: 0 27px;
        .item_label {
          line-height: 75px;
          border-bottom: 1px solid #e9e9e9;
          font-size: 20px;
          font-weight: bold;
        }
      }
      .item_list {
        padding: 26px 0;
        font-size: 16px;
        font-weight: 400;
        color: #585654;
        line-height: 30px;
        display: flex;
        flex-wrap: wrap;
        p {
          width: calc(100% / 3);
          &:nth-of-type(3n - 1) {
            text-align: center;
          }
          &:nth-of-type(3n) {
            text-align: right;
          }
          &.active {
            color: #3c81ce;
            &:after {
              content: " ";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background: #1769c5;
            }
          }
        }
      }
      .item_column {
        padding: 26px 0;
        font-size: 17px;
        font-weight: 300;
        line-height: 22px;
        p {
          margin-bottom: 29px;
          overflow: hidden;
          display: -webkit-box; //将元素设为盒子伸缩模型显示
          -webkit-box-orient: vertical; //伸缩方向设为垂直方向
          -webkit-line-clamp: 2; //超出3行隐藏，并显示省略号
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`

class ReportDetail extends React.PureComponent {
  constructor(props) {
    super(props)
    let search = this.props.location.search
    search = search.substr(1,search.length)
    const qryParam = JSON.parse(decodeURI(search));
    this.state = {
      qryText:qryParam.qryText,
      idx: 1,
      loading: true,
      report: {},
      moreList: [],
      currentGuid: "",
      currentMenuId: "",
      menuList: [], //this.props.menuList,
      tabList: [
        {
          label: "机构关注研报",
          value: 1,
        },
        {
          label: "热门行业研报",
          value: 2,
        },
        {
          label: "首次评级研报",
          value: 3,
        },
      ],
    }
  }
  componentDidMount() {
    let guid = this.props.match.params.id //this.props.guid //this.props.match.params.id
    //let more = this.props.location.more
    let more = api.localGet(config.meeting_more_key)
    console.log("more===", more)
    if (api.convertNull(more) != "") {
      let aryMore = JSON.parse(more)
      let moreList = aryMore.filter((t) => {
        return t.guid != guid
      })
      console.log("moreList===", moreList)
      this.setState({
        moreList: moreList,
      })
    }
    // if (more && more.reportList && more.reportList.length > 0) {
    //   let moreList = more.reportList.filter((t) => {
    //     return t.guid != guid
    //   })
    //   console.log("moreList===", moreList)
    //   this.setState({
    //     moreList: moreList,
    //   })
    // }
    //console.log("this.props.guid==", this.props.guid)
    //console.log("this.props.menuList==", this.props.menuList)
    if (api.checkLogin()) {
      this.getMeetingDetail(guid)
      let menuList = api.localGet(config.meeting_menu_key)
      console.log("menuList from local==", menuList)
      if (menuList && menuList.length > 0) {
        this.setState({
          menuList: JSON.parse(menuList),
        })
      }
    } else {
      message.error("请登陆")
      this.props.history.push("/")
    }
  }
  getMoreReport(qryText, qryType) {
    api.getMoreReport(qryText, qryType).then((res) => {
      if (res && res.Table && res.Table.length > 0) {
        let moreList = res.Table.filter((t) => {
          return t.GUID != this.state.currentGuid
        })
        this.setState({
          moreList: moreList,
        })
      }
    })
  }
  changeMenu(clickMenuId, index) {
    console.log("detail clickMenuId===", clickMenuId)
    console.log("call father..")
    //this.props.detailMenuClick(v)
    // this.props.changeMenu(v, index)
    console.log("this.props", this.props)
    this.props.history.push({ pathname: "/meeting", state: { clickMenuId } })
  }
  goDetail(guid) {
    this.getMeetingDetail(guid)
  }
  getMeetingDetail(guid) {
    console.log("guid==", guid)
    this.setState({
      loading: true,
      currentGuid: guid,
    })
    api
      .getMeetingDetail(guid)
      .then((res) => {
        console.log("res===", res)
        let report =
          res && res.Table && res.Table.length > 0 ? res.Table[0] : {}
        report.SUMMARY =
          api.convertNull(report.SUMMARY) == ""
            ? ""
            : api.splitContent(api.convetHtml(report.SUMMARY))
            report.SUMMARY = api.qryhightlight(report.SUMMARY,this.state.qryText)    
        this.setState({
          report: report,
          currentMenuId: report.FTYPE,
          loading: false,
        })
        console.log("report==", report)
        // let qryText = report.AUTHOR == "" ? report.FSOURCE : report.AUTHOR
        // let qryType = report.AUTHOR == "" ? "出处" : "作者"
        // this.getMoreReport(qryText, qryType)
        // setTimeout(() => {
        //   this.setState({
        //     loading: false,
        //   })
        // }, 1000)
      })
      .catch((error) => {
        setTimeout(() => {
          this.setState({
            loading: false,
          })
        }, 1000)
      })
  }
  changeTab(v) {
    this.setState({
      idx: v,
      loading: true,
    })
    setTimeout(() => {
      this.setState({
        loading: false,
      })
    }, 1000)
  }
  openPdf() {
    let pdf =
      "http://www.huijuzx.com/weixinpt/uploads/meetingInvest/" +
      this.state.report.FILEPATH
    console.log("pdf==", pdf)
    window.open(pdf, "_blank")
  }
  render() {
    const { tabList, idx, loading, report, moreList, menuList, currentMenuId } =
      this.state
    return (
      <Box>
        <Spin spinning={loading}>
          {/* <div className="top">
            <div className="contarin">
              <ul>
                {tabList.map((t) => {
                  return (
                    <li
                      key={t.value}
                      className={idx === t.value ? "active" : ""}
                      onClick={() => this.changeTab(t.value)}
                    >
                      {t.label}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div> */}
          <div className="text-content maxContrain">
            <div className="text">
              <p className="title">
                {report.FTITLE}
                {/* <ImportOutlined
                  onClick={() =>
                    this.changeMenu(
                      this.props.currentMenuId,
                      this.props.currentMenuIndex
                    )
                  }
                  style={{
                    float: "right",
                    margin: "10px",
                    color: "#3c81ce",
                  }}
                /> */}
              </p>
              <div className="tag">
                <p className="resource">
                  <span>{report.FSOURCE}</span>
                  {/* <section>{report.REPORTTYPENAME}</section> */}
                </p>
                {/* {report.REPORTTYPENAME ? (
                  <div className="resource">
                    <section>{report.REPORTTYPENAME}</section>
                  </div>
                ) : null}
                <p className="createUser">
                  <img src={user} alt="" />
                  {report.AUTHOR}
                </p> */}

                {report.INDUSTRYNAME ? (
                  <section className="stock">{report.INDUSTRYNAME}</section>
                ) : null}

                {report.STOCKNAME ? (
                  <p className="stock">个股：{report.STOCKNAME}</p>
                ) : null}

                <p className="time">{report.CREATEDATE1}</p>
                {/* <p className="stock">个股：{report.STOCKNAME}</p>
                <p className="level">评级：{report.INVESTNAME}</p> */}
              </div>
              {/* <p className="time">{report.CREATEDATE1}</p> */}
              <p className="pdf">
                <img src={pdf} alt="" />
                {report.FILEPATH ? (
                  <a href="#!" onClick={() => this.openPdf()}>
                    {report.FILENAME}
                  </a>
                ) : (
                  <span>{report.FILENAME}</span>
                )}
              </p>
              <Divider orientation="left" plain>
                <div>摘要</div>
              </Divider>

              <div
                className="innerhtml"
                dangerouslySetInnerHTML={{
                  __html: report.SUMMARY,
                }}
              ></div>
            </div>
            <div className="recommand">
              <div className="item">
                <p className="item_label">机构会议</p>
                {menuList ? (
                  <div className="item_list">
                    {menuList.map((t, i) => {
                      return (
                        <p
                          key={t.value}
                          className={currentMenuId === t.value ? "active" : ""}
                          onClick={this.changeMenu.bind(this, t.value, i)}
                        >
                          {/* <Link
                            to={{
                              pathname: "/report",
                              query: { clickMenuId: t.value },
                            }}
                          > */}
                          {t.label}
                          {/* </Link> */}
                        </p>
                      )
                    })}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="item">
                <p className="item_label">推荐阅读</p>
                <div className="item_column">
                  {
                    //new Array(5).fill(1).map((t, i) => {
                    moreList.map((t, i) => {
                      return (
                        <p key={i} onClick={() => this.goDetail(t.guid, i)}>
                          {t.title}
                        </p>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Box>
    )
  }
}
export default ReportDetail
