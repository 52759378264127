import React from "react"
import { NavLink, Link } from "react-router-dom"
import styled from "styled-components"
import share from "../../assets/imgs/report/bg.png"
import search from "../../assets/imgs/report/search.png"
import search_a from "../../assets/imgs/report/search_a.png"

import MyInfo from "./components/myInfo"
import Contact from "./components/contact"
import MyBuy from "./components/myBuy"
import FeedBack from "./components/feedBack"
import DownHis from "./components/downHis"
import UpdatePwd from "./components/updatePwd"
import ApplyToken from "./components/applyToken"

import {
  message,
  Spin,
  Radio,
  Pagination,
  DatePicker,
  Space,
  Form,
  Input,
  Col,
  Row,
  Switch,
  Select,
  Button,
} from "antd"
import {
  CaretUpOutlined,
  CaretDownOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import api from "../../util/api"
import config from "../../util/config"
import CheckableTag from "antd/lib/tag/CheckableTag"
import { thisExpression, throwStatement } from "@babel/types"
import moment from "moment"

const { RangePicker } = DatePicker

const Box = styled.div`
  background: #f4f5f7;
  min-height: 100vh;
  font-size: 17px;
  .btn {
    margin-left: 40px;
    margin-bottom: 40px;
  }
  .top {
    background: #f4f5f7;
    border-bottom: 1px solid #cdced0;
    position: sticky;
    top: 68px;
    left: 0;
    z-index: 100;
  }
  .contarin {
    width: 1400px;
    margin: 0 auto;
    height: 56px;
    ul {
      padding: 0 60px;
      display: flex;
      align-items: center;
      li {
        line-height: 56px;
        margin-right: 81px;
        cursor: pointer;
        font-size: 17px;
        color: #585654;
        position: relative;
        &.active {
          color: #3c81ce;
          &:after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: #1769c5;
          }
        }
      }
    }
  }
  .list {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    background: url(${share}) no-repeat;
    background-size: 100%;
    min-height: 50vh;
    .list_content {
      flex: 1;
      //margin-left: 20px;
      width: 1160px;
      margin: 0 auto;
      /* padding-top: 83px;*/
      .reportList {
        padding: 28px 70px;
        background: #fff;
        ul {
          color: #585654;
          li {
            display: inline-block;
            cursor: pointer;
            margin: 0 17px;
            position: relative;
            &:after {
              content: " ";
              position: absolute;
              height: 17px;
              width: 1px;
              right: -18px;
              top: 50%;
              transform: translateY(-50%);
              background: #585654;
            }
            &:last-child {
              &:after {
                display: none;
              }
            }
            &.active {
              &:before {
                content: " ";
                position: absolute;
                width: 100%;
                height: 1px;
                background: #585654;
                bottom: 0;
                left: 0;
              }
            }
          }
        }
      }
      .title {
        margin: 22px;
      }
      .search {
        padding: 31px 30px;
        background: #fff;
        .search_ipt {
          display: flex;
          cursor: pointer;
          align-items: center;
          ._ipt {
            flex: 1;
            display: flex;
            p {
              input {
                width: 100%;
                height: 100%;
                border: none;
                padding: 0 10px;
                outline: none;
              }
              &:first-child {
                flex: 1;
                height: 47px;
                background: #f7fafd;
                border: 1px solid #585654;
              }
              &:last-child {
                width: 130px;
                height: 47px;
                background: url(${search}) no-repeat;
                background-size: 100% 100%;
                border-radius: 0px 3px 3px 0px;
                &:active {
                  background: url(${search_a}) no-repeat;
                  background-size: 100% 100%;
                }
              }
            }
          }
          ._iptdate {
            flex: 1;
            display: flex;
            p {
              div {
                width: 100%;
                height: 100%;
                border: none;
                padding: 0 10px;
                outline: none;
              }
              &:first-child {
                flex: 1;
                height: 47px;
                background: #f7fafd;
                border: 1px solid #585654;
              }
              &:last-child {
                width: 130px;
                height: 47px;
                background: url(${search}) no-repeat;
                background-size: 100% 100%;
                border-radius: 0px 3px 3px 0px;
                &:active {
                  background: url(${search_a}) no-repeat;
                  background-size: 100% 100%;
                }
              }
            }
          }
          ._sbtn {
            &:active {
              box-shadow: 0 0 5px #aaa;
            }
            width: 71px;
            margin-left: 22px;
            line-height: 47px;
            color: #ffffff;
            text-align: center;
            background: linear-gradient(90deg, #49a3e3 0%, #317acc 100%);
            border-radius: 0px 3px 3px 0px;
            border: 1px solid #49a3e3;
          }
          ._btn {
            &:active {
              box-shadow: 0 0 5px #aaa;
            }
            width: 151px;
            margin-left: 22px;
            line-height: 47px;
            color: #ffffff;
            text-align: center;
            background: linear-gradient(90deg, #49a3e3 0%, #317acc 100%);
            border-radius: 0px 3px 3px 0px;
            border: 1px solid #49a3e3;
          }
        }
        .search_list {
          margin-top: 29px;
          .ant-radio-wrapper {
            color: #3c81ce;
          }
        }
      }
      .table {
        margin-bottom: 30px;
        li {
          cursor: pointer;
          height: 80px;
          background: #ffffff;
          padding: 13px 22px;
          margin-top: 1px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &:hover {
            box-shadow: 0 0 10px #ccc;
          }
          a {
            font-size: 15px;
            color: #585654;
            &:first-child {
              font-size: 16px;
              color: rgb(51, 51, 51);
              font-weight: bold;
            }
            &:last-child {
              span {
                &:first-child {
                  margin-right: 46px;
                }
              }
            }
          }
          p {
            font-size: 12px;
            color: rgb(153, 153, 153);
            &:first-child {
              font-size: 16px;
              color: rgb(51, 51, 51);
              font-weight: bold;
            }
            &:last-child {
              span {
                &:first-child {
                  margin-right: 46px;
                }
              }
            }
          }
        }
      }
    }
    .recommand {
      flex: 1;
      flex: 0 0 15%;
      margin-left: 20px;
      .item {
        margin-bottom: 21px;
        background: #fff;
        padding: 0 27px;
        .item_label {
          line-height: 75px;
          border-bottom: 1px solid #e9e9e9;
          font-size: 20px;
          font-weight: bold;
        }
      }
      .item_list {
        padding: 16px 0;
        font-size: 16px;
        font-weight: 400;
        color: #585654;
        line-height: 30px;
        display: flex;
        flex-wrap: wrap;
        p {
          width: 90%;
          text-align: center;
          &.active {
            color: #3c81ce;
            &:after {
              content: " ";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background: #1769c5;
            }
          }
        }
      }
      .item_column {
        padding: 26px 0;
        font-size: 17px;
        font-weight: 300;
        line-height: 22px;
        p {
          margin-bottom: 29px;
          overflow: hidden;
          display: -webkit-box; //将元素设为盒子伸缩模型显示
          -webkit-box-orient: vertical; //伸缩方向设为垂直方向
          -webkit-line-clamp: 2; //超出3行隐藏，并显示省略号
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`

class Mine extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tabValue: 1,
      loading: true,
      tabList: [
        {
          label: "我的信息",
          value: 1,
        },
        {
          label: "接口Token",
          value: 7,
        },
        {
          label: "我的客服",
          value: 2,
        },
        // {
        //   label: "我要购买",
        //   value: 3,
        // },
        {
          label: "我的足迹",
          value: 4,
        },
        {
          label: "修改密码",
          value: 5,
        },
        {
          label: "意见反馈",
          value: 6,
        },
      ],
      contacts: [],
      prices: [],
      myInfo: {},
      hisList: [],
      applyHis: [],

    }
  }
  componentDidMount() {
    if (api.checkLogin()) {
      this.getMyInfo()
    } else {
      message.error("请登陆")
      this.props.history.push("/")
    }
  }
  logOut() {
    api.localClear()
    this.props.history.push("/")
  }
  getMyInfo() {
    api.getMyInfo().then((res) => {
      this.setState({
        myInfo: res && res.Table && res.Table.length > 0 ? res.Table[0] : {},
        loading: false,
        applyHis:res && res.Table2 ? res.Table2:[]
      })
    })
  }
  getContact() {
    api.getContact().then((res) => {
      this.setState({
        contacts: res && res.Table && res.Table.length > 0 ? res.Table : [],
        loading: false,
      })
    })
  }
  getPrice() {
    api.getBuy().then((res) => {
      this.setState({
        prices: res && res.Table && res.Table.length > 0 ? res.Table : [],
        loading: false,
      })
    })
  }
  getDownHis() {
    api.getDown().then((res) => {
      this.setState({
        hisList: res && res.Table && res.Table.length > 0 ? res.Table : [],
        loading: false,
      })
    })
  }
  changeTab(v) {
    this.setState({
      tabValue: v,
      loading: true,
    })
    setTimeout(() => {
      this.setState({
        loading: false,
      })
    }, 1000)
    if (v == 1) this.getMyInfo()
    else if (v == 2) this.getContact()
    else if (v == 3) this.getPrice()
    else if (v == 4) this.getDownHis()
  }

  render() {
    const { tabList, tabValue, loading, myInfo, prices, contacts, hisList ,applyHis} =
      this.state
    return (
      <Box>
        <div className="top">
          <div className="contarin">
            <ul>
              {tabList.map((t, i) => {
                return (
                  <li
                    key={"tab-" + i}
                    className={tabValue === t.value ? "active" : ""}
                    onClick={() => this.changeTab(t.value)}
                  >
                    {t.label}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className="list">
          <div
            style={{
              background:
                "linear-gradient(to bottom, #E5F1FF 0%, transparent 20%)",
            }}
          >
            <div className="list_content">
              {tabValue == 1 ? (
                <div>
                  <MyInfo myInfo={myInfo}></MyInfo>{" "}
                  <p>
                    <Button
                      type="primary"
                      className="btn"
                      onClick={() => this.logOut()}
                    >
                      注销登录
                    </Button>
                  </p>
                </div>
              ) : null}
              {tabValue == 2 ? <Contact list={contacts}></Contact> : null}
              {tabValue == 3 ? <MyBuy list={prices}></MyBuy> : null}
              {tabValue == 4 ? <DownHis list={hisList}></DownHis> : null}
              {tabValue == 5 ? <UpdatePwd></UpdatePwd> : null}
              {tabValue == 6 ? <FeedBack list={prices}></FeedBack> : null}
              {tabValue == 7 ? <ApplyToken myInfo={myInfo} applyHis={applyHis}></ApplyToken> : null}
            </div>
          </div>
        </div>
      </Box>
    )
  }
}
export default Mine
