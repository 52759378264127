import React from "react"
import { NavLink, Link } from "react-router-dom"
import styled from "styled-components"
import {
  message,
  Spin,
  Radio,
  Pagination,
  DatePicker,
  Space,
  Form,
  Input,
  Col,
  Row,
  Switch,
  Select,
  Button,
  Descriptions,
  Menu,
} from "antd"
import {
  CaretUpOutlined,
  CaretDownOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import api from "../../util/api"
import { thisExpression, throwStatement } from "@babel/types"
import moment from "moment"

const IndexCss = styled.div`
  .form {
    margin-top: 40px;
  }
`

class ResetPwd extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tabValue: 1,
      loading: false,
    }
  }
  componentDidMount() {}

  onFormFinish(values) {
    console.log("values===", values)
    this.setState({
      loading: true,
    })
    api.resetPwd(this.props.match.params.id, values.txtPassword).then((res) => {
      console.log("res=", res)
      this.setState({
        loading: false,
      })
      if (res && res.valid) {
        message.success("提交成功")
        //this.props.history.push("/")
      } else {
        let errors = res.errors || "对不起，提交失败! "
        message.error(errors)
      }
    })
  }

  render() {
    const { loading } = this.state

    const validatorPwd = ({ getFieldValue }) => ({
      validator(rule, value) {
        if (getFieldValue("txtPassword") == value) return Promise.resolve()
        else return Promise.reject("两次密码输入不一致")
      },
    })

    return (
      <IndexCss>
        <Spin spinning={loading}>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className="form"
            onFinish={this.onFormFinish.bind(this)}
          >
            <Form.Item
              label="密码"
              name="txtPassword"
              rules={[{ required: true, message: "请填写网站密码!" }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="确认密码"
              name="txtRePassword"
              rules={[
                { required: true, message: "请填写网站密码!" },
                validatorPwd,
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </IndexCss>
    )
  }
}
export default ResetPwd
