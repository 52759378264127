import React from "react"
import { NavLink, Link } from "react-router-dom"
import styled from "styled-components"
import share from "../../assets/imgs/report/bg.png"
import search from "../../assets/imgs/report/search.png"
import search_a from "../../assets/imgs/report/search_a.png"
import CaptchaMini from "captcha-mini"
import {
  message,
  Spin,
  Radio,
  Pagination,
  DatePicker,
  Space,
  Form,
  Input,
  Col,
  Row,
  Switch,
  Select,
  Button,
} from "antd"
import {
  CaretUpOutlined,
  CaretDownOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import api from "../../util/api"
import config from "../../util/config"
import CheckableTag from "antd/lib/tag/CheckableTag"
import { thisExpression, throwStatement } from "@babel/types"
import moment from "moment"

const { RangePicker } = DatePicker

const Box = styled.div`
  background: #f4f5f7;
  min-height: 100vh;
  font-size: 17px;
  .top {
    background: #f4f5f7;
    border-bottom: 1px solid #cdced0;
    position: sticky;
    top: 68px;
    left: 0;
    z-index: 100;
  }
  .form {
    padding: 30px 20px 0px 20px;
  }
`

class Regist extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      captchaCode: "",
    }
  }
  componentDidMount() {
    let captcha1 = new CaptchaMini()
    captcha1.draw(document.querySelector("#captcha1"), (r) => {
      console.log(r, "验证码1")
      this.setState({
        captchaCode: r.toUpperCase(),
      })
    })
  }

  onFormFinish(values) {
    console.log("values===", values)
    this.setState({
      loading: true,
    })
    console.log("captchaCode==", this.state.captchaCode)
    api.regist(values).then((res) => {
      console.log("res=", res)
      this.setState({
        loading: false,
      })
      if (res && res.valid) {
        message.success("注册成功")
        this.props.history.push("/")
      } else {
        let errors = res.errors || "对不起，注册失败! "
        message.error(errors)
      }
    })
  }
  /**
   *
   * @param {} rule
   * @param {String} vaule
   * @param {Function} callback
   */
  checkPsd(rule, value, callback) {
    let password2 = this.props.form.getFieldValue("txtPassword")
    if (password2 && password2 !== value) {
      console.log("a==")
      callback(new Error("两次密码输入不一致"))
    } else {
      callback()
    }
  }

  render() {
    const { loading, captchaCode } = this.state
    const validatorPwd = ({ getFieldValue }) => ({
      validator(rule, value) {
        if (getFieldValue("txtPassword") == value) return Promise.resolve()
        else return Promise.reject("两次密码输入不一致")
      },
    })

    const validatorRescode = ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value.toUpperCase() == captchaCode) return Promise.resolve()
        else return Promise.reject("请输入正确的验证码")
      },
    })

    return (
      <Box>
        <Spin spinning={loading}>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className="form"
            onFinish={this.onFormFinish.bind(this)}
          >
            <Form.Item
              label="真实姓名"
              name="txtFname"
              rules={[{ required: true, message: "请填写真实姓名!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="登陆名"
              name="txtUserName"
              rules={[{ required: true, message: "请填写网站登陆名!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="密码"
              name="txtPassword"
              rules={[{ required: true, message: "请填写网站密码!" }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="确认密码"
              name="txtRePassword"
              rules={[
                { required: true, message: "请填写网站密码!" },
                validatorPwd,
                // {
                //   validator: (rule, value, callback) => {
                //     this.checkPsd(rule, value, callback)
                //   },
                // },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="邮箱"
              name="txtEmail"
              rules={[
                {
                  required: true,
                  message: "请填写有效的邮箱,用于接收验证码和找回密码!",
                },
                { type: "email", message: "请填写有效的邮箱" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="手机" name="txtMobile">
              <Input />
            </Form.Item>

            <Form.Item
              label="验证码"
              name="txtCode"
              rules={[
                // {
                //   required: true,
                //   message: "请填写验证码！",
                // },
                validatorRescode,
                // {
                //   validator: (rules, value, callback) => {
                //     this.validatorRescode(rules, value, callback)
                //   },
                // },
              ]}
            >
              <Input />
            </Form.Item>

            <Row>
              <Col span={8}></Col>
              <Col span={16}>
                <canvas
                  width="170"
                  height="45"
                  id="captcha1"
                  name="captcha1"
                ></canvas>
              </Col>
            </Row>
            {/* <img src="" alt="" /> */}

            <Form.Item
              name="captchaCode"
              hidden="true"
              vaule={captchaCode}
            ></Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Box>
    )
  }
}
export default Regist
