import React from "react"
import styled from "styled-components"
import { NavLink, Link } from "react-router-dom"
import { Spin, message, Divider } from "antd"
import "./style/detail.scss"
import { ImportOutlined } from "@ant-design/icons"
import user from "../../assets/imgs/report/user.png"
import img_pdf from "../../assets/imgs/index/pdf.png"
import img_doc from "../../assets/imgs/index/word.png"
import img_xlsx from "../../assets/imgs/index/xlsx.png"
import img_txt from "../../assets/imgs/index/txt.png"
import img_file from "../../assets/imgs/index/file.png"
import api from "../../util/api"
import { throwStatement } from "@babel/types"
import config from "../../util/config"

class ReportDetail extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      idx: 1,
      loading: true,
      report: {},
      moreList: [],
      currentGuid: "",
      menuList: [], //this.props.menuList,
    }
  }
  componentDidMount() {
    let guid = api.decode(this.props.match.params.id) //this.props.guid //this.props.match.params.id
    //console.log("this.props.guid==", this.props.guid)
    //console.log("this.props.menuList==", this.props.menuList)
    if (api.checkLogin()) {
      this.getViewDetail(guid)
    } else {
      message.error("请登陆")
      this.props.history.push("/")
    }
  }
  getMoreView(qrySource) {
    console.log("qrySource=", qrySource)
    api.getCorpViewList("", "", 1, 11, "", "", qrySource, "").then((res) => {
      console.log("more res==", res)
      if (res) {
        let moreList = res.filter((t) => {
          return t.ID != this.state.currentGuid
        })
        this.setState({
          moreList: moreList,
        })
      }
    })
  }
  goDetail(guid) {
    this.getViewDetail(guid)
  }
  getViewDetail(guid) {
    console.log("guid==", guid)
    this.setState({
      loading: true,
      currentGuid: guid,
    })
    api
      .getCorpViewDetail(guid)
      .then((res) => {
        console.log("res===", res)
        let report =
          res && res.Table && res.Table.length > 0 ? res.Table[0] : {}
        console.log("report===", report)
        report.InvestmentView =
          api.convertNull(report.InvestmentView) == ""
            ? ""
            : api.splitContent(api.convetHtml(report.InvestmentView))
        this.setState({
          report: report,
        })
        this.getMoreView(report.Source)
        setTimeout(() => {
          this.setState({
            loading: false,
          })
        }, 1000)
      })
      .catch((error) => {
        setTimeout(() => {
          this.setState({
            loading: false,
          })
        }, 1000)
      })
  }

  openPdf(filePath) {
    let pdf = "http://www.huijuzx.com/weixinpt/uploads/corpView/" + filePath
    console.log("pdf==", pdf)
    window.open(pdf, "_blank")
  }
  getFileTypeImgSrc(fileType) {
    console.log("fileType==", fileType)
    if (fileType == ".pdf") return img_pdf
    else if (fileType == ".doc" || fileType == ".docx") return img_doc
    else if (fileType == ".xls" || fileType == ".xlsx") return img_xlsx
    else if (fileType == ".txt") return img_txt
    else return img_file
  }
  render() {
    const { idx, loading, report, moreList, menuList, currentMenuId } =
      this.state
    return (
      <div className="box">
        <Spin spinning={loading}>
          {/* <div className="top">
            <div className="contarin">
              <ul>
                {tabList.map((t) => {
                  return (
                    <li
                      key={t.value}
                      className={idx === t.value ? "active" : ""}
                      onClick={() => this.changeTab(t.value)}
                    >
                      {t.label}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div> */}
          <div className="text-content maxContrain">
            <div className="text">
              <p className="title">
                {report.Title}
                {/* <ImportOutlined
                  onClick={() =>
                    this.changeMenu(
                      this.props.currentMenuId,
                      this.props.currentMenuIndex
                    )
                  }
                  style={{
                    float: "right",
                    margin: "10px",
                    color: "#3c81ce",
                  }}
                /> */}
              </p>
              <div className="tag">
                <p className="resource">
                  <span>{report.Source}</span>
                  {/* <section>{report.REPORTTYPENAME}</section> */}
                </p>

                {report.Author ? (
                  <p className="createUser">
                    <img src={user} alt="" />
                    {report.Author}
                  </p>
                ) : null}
                <p className="time">{report.CREATEDATE1}</p>
              </div>

              <p className="pdf">
                {report.FILETYPE ? (
                  <img src={this.getFileTypeImgSrc(report.FILETYPE)} alt="" />
                ) : null}
                {report.FilePath ? (
                  <a href="#!" onClick={() => this.openPdf(report.FilePath)}>
                    {report.FileName}
                  </a>
                ) : (
                  <span>{report.FileName}</span>
                )}
              </p>
              <Divider orientation="left" plain>
                <div className="ipyGSt">摘要</div>
              </Divider>

              <div
                className="innerhtml"
                dangerouslySetInnerHTML={{
                  __html: report.InvestmentView,
                }}
              ></div>
            </div>
            <div className="recommand">
              <div className="item"></div>
              <div className="item">
                <p className="item_label">推荐阅读</p>
                <div className="item_column">
                  {
                    //new Array(5).fill(1).map((t, i) => {
                    moreList.map((t, i) => {
                      return (
                        <p key={i} onClick={() => this.goDetail(t.ID, i)}>
                          {t.FTITLE}
                          {/* <span style={{ float: "right" }}>{t.CREATETIME}</span> */}
                        </p>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    )
  }
}
export default ReportDetail
