export default {
  server_url: "https://www.huijuzx.com/ServicHandler.ashx?opType=",
  server_url_app: "https://www.huijuzx.com/Handler_app.ashx?opType=",
  pay_url: "https://www.huijuzx.com/KlcjWxPayHandler.ashx?opType=",
  user_key: "klcj_user_react",
  menu_key: "klcj_menu_react",
  meeting_menu_key: "klcj_meeting_menu_react",
  meeting_more_key: "klcj_meeting_more_react",
  tpsNews_menu_key: "klcj_tpsNews_menu_react",
  tpsNews_more_key: "klcj_tpsNews_more_react",
  industry_key: "klcj_industry_react",
  save_time_key: "klcj_time_react",
  dateFormat: "YYYY-MM-DD",
  pageSize: 10,
}
