import React from "react"
import moment from "moment"
import "./style/index.scss"
import {
  Spin,
  Input,
  Select,
  Table,
  Pagination,
  Tabs,
  DatePicker,
  message,
  AutoComplete,
  Popover,
  Button,
  Tooltip,
  Form,
  Row,
  Col,
  Modal,
} from "antd"
import { DownOutlined, UpOutlined, ReloadOutlined } from "@ant-design/icons"

import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"

import img_pdf from "../../assets/imgs/index/pdf.png"
import img_doc from "../../assets/imgs/index/word.png"
import img_xlsx from "../../assets/imgs/index/xlsx.png"
import img_txt from "../../assets/imgs/index/txt.png"
import img_file from "../../assets/imgs/index/file.png"

import api from "../../util/api"
import config from "../../util/config"

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom"

const { Column } = Table
const { RangePicker } = DatePicker

const ColumnTitle = (title, qryStr) => (
  <span>
    {title}
    {/* {qryStr ? (
      <Tooltip title="刷新">
        <ReloadOutlined
          style={{ marginLeft: "1.25em" }}
          onClick={() => this.reload.bind(this)}
        />
      </Tooltip>
    ) : null} */}
  </span>
)

class Group extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isMoreStock: false,
      loading: false,
      idx: 1,
      indusyHotData: [],
      firstAvgFieldByMonth: 0,
      firstAvgFieldByYear: 0,
      industryDistribute: [],
      indusyHotText: "",
      tabList: [
        {
          label: "机构观点",
          value: 1,
        },
        {
          label: "机构社群",
          value: 2,
        },
        {
          label: "私募奖项",
          value: 3,
        },
      ],
      awardTab:['券商私募大赛','机构私募大赛'],
      awardTabIndex:0,

      text: "券商金股",
      bigDataTab: ["券商", "公募", "私募"],
      bigDataTabIndex: 0,
      bigDataTab1: [
        "券商研究所",
        "券商资管",
        "券商自营",
        "公募",
        "私募",
        "保险",
        "银行资管",
        "信托公司",
        "财务公司",
        "集团公司",
        "境外金融",
        "期货资管",
        "上市公司",
        "一级市场",
      ],
      bigDataTabIndex1: 0,
      multipleList: [],
      firstList: [],
      subFirstList: [],
      industryList: [],
      tableData: [],
      allTableData: [],
      numberData: {},
      options: [],
      pageIndex: 1,
      pageSize: 10,
      qrySource: "",
      qryAuthor: "",
      qryDateS: "",
      qryDateE: "",
      qryText: "",
      qryType: "",
      qryReportType:'',
      agentTypeList: [],
      reportTypeList:[],
      awardTypeList:[],
      qryText_group: "",
      qryCity: "",
      qryType_group: "",
      pageIndex_group: 1,
      pageSize_group: 10,
      tableData_group: [],
      initAgentType: "",
      selectAgentTypeCode: "",
      initGroupData: false,

      initAwardData: false,
      qryDateS_award: "",
      qryDateE_award: "",
      qrySource_award: "",
      qryText_award: "",
      qryType_award: "",
      pageIndex_award: 1,
      tableData_award: [],
      tableData_award_detail:[],
      isAwardModalOpen:false,
    }
  }

  componentDidMount() {
    if (api.checkLogin()) {
      this.getData()
    } else {
      message.error("请登陆")
      this.props.history.push("/")
    }
  }
  getData() {
    this.getCorpView()
    //this.getCorpGroup()
    this.getAgentType()
  }
  getAgentType() {
    let _this = this
    api.getCode("corpViewAgentType", 1, 100).then((res) => {
      console.log("a=", res)
      if (res && res.Table) {
        let list = []
         list.push({
           label: "请选择",
           value: "",
         })
        res.Table.map((t) => {
          list.push({
            label: t.NAME,
            value: t.NAME,
            note: t.CODE,
          })
        })
        let defaultValue = list.length > 0 ? list[0].value : ""
        let defaultCode = list.length > 0 ? list[0].value : ""
        this.setState({
          agentTypeList: list,
          //initAgentType: defaultValue,
          qryType_group: defaultValue,
        })
      }
    })

    api.getCode("CorpViewReportType", 1, 100).then((res) => {
      console.log("a=", res)
      if (res && res.Table) {
        let list = []
         list.push({
           label: "请选择",
           value: "",
         })
        res.Table.map((t) => {
          list.push({
            label: t.NAME,
            value: t.CODE,
            note: t.CODE,
          })
        })
        this.setState({
          reportTypeList: list,
          
        })
      }
    })
    
    api.getCode("CorpAwardType", 1, 100).then((res) => {
      console.log("a=", res)
      if (res && res.Table) {
        let list = []
         list.push({
           label: "请选择",
           value: "",
         })
        res.Table.map((t) => {
          list.push({
            label: t.NAME,
            value: t.CODE,
            note: t.CODE,
          })
        })
        
        this.setState({
          awardTypeList: list,
        })
      }
    })
  }

  goSource(qrySource, qryAuthor) {
    this.setState(
      {
        qrySource: qrySource,
        qryAuthor: qryAuthor,
        pageIndex: 1,
        qryText: "",
        qryType: "",
      },
      () => {
        this.getCorpView()
      }
    )
  }
  goSource_award(qrySource, qryAuthor) {
    this.setState(
      {
        qrySource_award: qrySource,
        pageIndex: 1,
        qryText_award: "",
        qryType_award: "",
      },
      () => {
        this.getCorpAward()
      }
    )
  }
  onDateChange(value, dateString) {
    console.log("Selected Time: ", value)
    console.log("Formatted Selected Time: ", dateString)
    this.setState({
      qryDateS: dateString[0],
      qryDateE: dateString[1],
    })
  }
  onDateChange_award(value, dateString) {
    this.setState({
      qryDateS_award: dateString[0],
      qryDateE_award: dateString[1],
    })
  }

  onFormFinishView(values) {
    this.setState(
      {
        qryText: api.convertNull(values.qryText),
        qryType: values.txtAgentType,
        pageIndex: 1,
        qrySource: api.convertNull(values.qrySource),
        qryAuthor: "",
        qryReportType:values.qryReportType,
      },
      () => {
        this.getCorpView()
      }
    )
    // this.seqQuery(values, 1)
  }
  onFormFinishAward(values) {
    this.setState(
      {
        qryText_award: api.convertNull(values.qryText),
        qryType_award: values.txtAgentType,
        pageIndex_award: 1,
        qrySource_award: api.convertNull(values.qrySource),
        qryAuthor: "",
      },
      () => {
        this.getCorpAward()
      }
    )
    // this.seqQuery(values, 1)
  }

  onAgentType_chg(value) {
    let agentTypeList = this.state.agentTypeList
    let ary = agentTypeList.filter((item) => {
      return item.value == value
    })
    return ary.length > 0 ? ary[0].note : ""
  }

  onFormFinishGroup(values) {
    //let selectAgentTypeCode = this.onAgentType_chg(values.txtAgentType_group)
    this.setState(
      {
        qryText_group: values.qryText_group,
        qryType_group: values.txtAgentType_group,
        qryCity: values.qryCity,
        pageIndex: 1,
      },
      () => {
        this.getCorpGroup()
      }
    )
    // this.seqQuery(values, 1)
  }

  reload() {
    this.setState(
      {
        qryText: "",
        qryDateS: "",
        qryDateE: "",
        pageIndex: 1,
        qrySource: "",
        qryAuthor: "",
        qryType: "",
      },
      () => {
        this.getCorpView()
      }
    )
  }
  reload_award() {
    this.setState(
      {
        qryText_award: "",
        qryDateS_award: "",
        qryDateE_award: "",
        pageIndex_award: 1,
        qrySource_award: "",
      },
      () => {
        this.getCorpAward()
      }
    )
  }
  changeAwardTab(index) {
    this.setState({
      awardTabIndex: index,
    },
    () => {
      this.reload_award()
    }
    )
  }

  getCorpView() {
    this.setState({
      loading: true,
    })

    api
      .getCorpViewList(
      this.state.qryType,
      this.state.qryText,
      this.state.pageIndex,
      this.state.pageSize,
      this.state.qryDateS,
      this.state.qryDateE,
      this.state.qrySource,
      this.state.qryAuthor,
      this.state.qryReportType,
      )
      .then((res) => {
        if (res) {
          let tableData = res ? res : []
          let total = tableData.length > 0 ? tableData[0].ALLCOUNT : []
          this.setState({
            tableData: tableData,
            loading: false,
            total: total,
          })
        }
      })
  }
  pageChange(page, pageSize) {
    this.setState(
      {
        pageIndex: page,
        pageSize: pageSize,
      },
      () => {
        this.getCorpView()
      }
    )
  }
  getCorpGroup() {
    this.setState({
      loading: true,
      initGroupData: true,
    })

    api
      .getCorpGroupList(
      this.state.qryType_group,
      this.state.qryText_group,
      this.state.pageIndex_group,
      this.state.pageSize_group,
      this.state.qryCity
      )
      .then((res) => {
        if (res) {
          let tableData = res ? res : []
          let total = tableData.length > 0 ? tableData[0].ALLCOUNT : []
          this.setState({
            tableData_group: tableData,
            loading: false,
            total_group: total,
          })
        }
      })
  }
  pageChange_group(page, pageSize) {
    this.setState(
      {
        pageIndex_group: page,
        pageSize_group: pageSize,
      },
      () => {
        this.getCorpGroup()
      }
    )
  }
  getCorpAward() {
    this.setState({
      loading: true,
      initAwardData:true,
    })

    api
      .getCorpAwardList(
      this.state.awardTabIndex,
      this.state.qryText_award,
      this.state.pageIndex_award,
      this.state.pageSize,
      this.state.qryDateS_award,
      this.state.qryDateE_award,
      this.state.qrySource_award
      )
      .then((res) => {
        if (res) {
          let tableData = res ? res : []
          let total = tableData.length > 0 ? tableData[0].ALLCOUNT : []
          this.setState({
            tableData_award: tableData,
            loading: false,
            total_award: total,
          })
        }
      })
  }
  pageChange_award(page, pageSize) {
    this.setState(
      {
        pageIndex_award: page,
        pageSize: pageSize,
      },
      () => {
        this.getCorpAward()
      }
    )
  }

  
  getFileTypeImgSrc(fileType) {
    console.log("fileType==", fileType)
    if (fileType == ".pdf") return img_pdf
    else if (fileType == ".doc" || fileType == ".docx") return img_doc
    else if (fileType == ".xls" || fileType == ".xlsx") return img_xlsx
    else if (fileType == ".txt") return img_txt
    else return img_file
  }

  goGroupDetail(detailId) {
    window.open("/group/details/" + api.encode(detailId)+'/'+api.encode(this.state.awardTabIndex), "_blank")
  }
  goViewDetail(detailId) {
    window.open("/group/viewDetails/" + api.encode(detailId), "_blank")
  }

  changeTab(value) {
    console.log("qryType_group==", this.state.qryType_group)
    this.setState(
      {
        idx: value,
        text: this.state.tabList.find((item) => item.value == value).label,
      },
      () => {
        if (value == 2 && !this.state.initGroupData) {
          this.getCorpGroup()
        }
        if (value == 3 && !this.state.initAwardData) {
          this.getCorpAward()
        }
      }
    )
  }

  openPdf(filePath) {
    let pdf = "http://www.huijuzx.com/weixinpt/uploads/corpView/" + filePath
    console.log("pdf==", pdf)
    window.open(pdf, "_blank")
  }
  sorterFun(pagination,filters,sorter){
    let sortType = sorter.order // 排序类型，正序/倒叙/不排序
    console.log('filters==',filters)
    console.log('sorter==',sorter)
    // if (sortType) {
    //   this.getDataList(sortType === 'ascend' ? 'asc' : 'desc')
    // } else {
    //   this.getDataList()
    // }
  }
  /**
 * 表格排序
 * @param propeties
 * @returns {function(*, *): *}
 */
 compareSortFunction(propeties) {
  var compareSort = (rowa, rowb) => sortFunction(rowa[propeties], rowb[propeties]);
  return compareSort;
}
sortFunction(a, b) {
  if (!a) {
    a = "";
  }
  if (!b) {
    b = "";
  }
  if ((typeof a) !== "number") {
    return a.localeCompare(b);
  }

}
showAwardModalOk(fullCode){
  this.setState({
     isAwardModalOpen:true
  })
  api
      .getCorpAwardDetail(
      this.state.awardTabIndex,
      fullCode,
      1,
      1000,
      ''
      )
      .then((res) => {
        if (res) {
          let tableData = res && res.data ? res.data : []
          let total = res && res.total ? res.total : []
          this.setState({
            tableData_award_detail: tableData,
            loading: false,
          })
        }
      })
}
handleAwardModalOk(){
  this.setState({
     isAwardModalOpen:false
  })
}

  render() {
    const {
      loading,
      idx,
      tabList,
      tableData,
      tableData_group,
      qrySource,
      qryAuthor,
      agentTypeList,
      total_group,
      total,
      pageSize,
      pageSize_group,
      pageIndex_group,
      initAgentType,
      qryType_group,
      qryText,
      qryText_award,
      qryType_award,
      qrySource_award,
      pageSize_award,
      pageIndex_award,
      total_award,
      tableData_award,
      tableData_award_detail,
      reportTypeList,
      awardTypeList,
      isAwardModalOpen,
    } = this.state

    return (
      <div className="goldStockPage">
        {/* 顶部tab */}
        <div className="top">
          <div className="contarin">
            <ul>
              {tabList.map((t, index) => {
                return (
                  <li
                    key={index}
                    className={idx === t.value ? "active" : ""}
                    onClick={() => this.changeTab(t.value)}
                  >
                    {t.label}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        {/* 内容部分  */}
        <Spin spinning={false}>
          <div className="content">
            <div className="content_bg">
              <div className="content_box">
                {idx === 1 ? (
                  /* 机构观点 */
                  <div className="goldStockBigData">
                    <div className="search">
                      <Form
                        name="basic"
                        onFinish={this.onFormFinishView.bind(this)}
                        autoComplete="off"
                      >
                        <Row gutter={24}>
                          <Col span={4} key={1}>
                            <Form.Item label="关键词" name="qryText">
                              <Input placeholder="标题、作者模糊搜索" />
                            </Form.Item>
                          </Col>
                          <Col span={6} key={2}>
                            <Form.Item label="起止日期" name="txtReqSDate">
                              <RangePicker
                                locale={locale}
                                onChange={this.onDateChange.bind(this)}
                                onOk={this.onDateOk_sim}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4} key={3}>
                            <Form.Item label="机构类型" name="txtAgentType">
                              <Select options={agentTypeList} />
                            </Form.Item>
                          </Col>
                          <Col span={3} key={4}>
                            <Form.Item label="出处" name="qrySource">
                              <Input placeholder="出处模糊搜索" />
                            </Form.Item>
                          </Col>
                          <Col span={4} key={5}>
                            <Form.Item label="报告分类" name="qryReportType">
                            <Select options={reportTypeList} />
                            </Form.Item>
                          </Col>
                          <Col span={3} key={6}>
                            <Button type="primary" htmlType="submit">
                              搜索
                            </Button>{" "}
                            &nbsp;
                            <Button
                              type="primary"
                              onClick={() => this.reload()}
                            >
                              刷新
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                    <Table
                      dataSource={tableData}
                      loading={loading}
                      pagination={{
                        position: ["none", "bottomCenter"],
                        onChange: this.pageChange.bind(this),
                        total: total,
                        pageSize: pageSize,
                      }}
                      className="futop"
                      size="middle"
                      rowKey={(record) => record.ID}
                      // onChange={this.sorterFun.bind(this)}
                    >
                      <Column
                        title="时间"
                        width={150}
                        align="center"
                        sorter={(a, b) => new Date(a.CREATETIME).getTime() - new Date(b.CREATETIME).getTime()}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.CREATETIME}</div>
                            </div>
                          )
                        }}
                      />
                      <Column
                        title="类型"
                        align="center"
                        width={70}
                        render={(record) => {
                          return (
                            <div>
                              {record.FILETYPE ? (
                                <a
                                  href="#!"
                                  onClick={() => this.openPdf(record.FilePath)}
                                >
                                  <img
                                    className="img"
                                    src={this.getFileTypeImgSrc(
                                      record.FILETYPE
                                    )}
                                  ></img>
                                </a>
                              ) : null}
                            </div>
                          )
                        }}
                      />
                      <Column
                        title="标题内容"
                        align="center"
                        sorter={(a: any, b: any) => a.FTITLE.localeCompare(b.FTITLE)}
                        render={(record) => {
                          return (
                            <div
                              onClick={() => this.goViewDetail(record.ID)}
                              className="link"
                              dangerouslySetInnerHTML={{
                                __html: api.qryhightlight(record.FTITLE, qryText),
                              }}></div>
                          )
                        }}
                      />
                      <Column
                        title="机构类型"
                        align="center"
                        dataIndex="FTYPE"
                        sorter={(a: any, b: any) => a.FTYPE.localeCompare(b.FTYPE)}
                        render={(FTYPE) => {
                          return <div>{FTYPE}</div>
                        }}
                      />{" "}
                      <Column
                        title="来源"
                        align="center"
                        sorter={(a: any, b: any) => a.SOURCE.localeCompare(b.SOURCE)}
                        render={(record) => {
                          return (
                            <div
                               onClick={() => this.goSource(record.SOURCE, "")}
                             // onClick={() => this.goGroupDetail(record.CompanyCode)}
                              className="link"
                            >
                              {record.SOURCE}
                            </div>
                          )
                        }}
                      />
                      <Column
                          title="报告分类"
                          align="center"
                          width={120}
                          sorter={(a: any, b: any) => a.ReportTypeName.localeCompare(b.ReportTypeName)}
                          render={(record) => {
                            return <div>{record.ReportTypeName}</div>
                          }}
                        />
                      
                      
                    </Table>
                  </div>
                ) : null}
                {idx === 2 ? (
                  /* 机构社群 */
                  <div className="goldStockBigData">
                    <div className="search">
                      <Form
                        name="basic"
                        onFinish={this.onFormFinishGroup.bind(this)}
                        autoComplete="off"
                      >
                        <Row gutter={24}>
                          <Col span={4} key={0}>
                            <Form.Item label="地区" name="qryCity">
                              <Input placeholder="地区模糊搜索" />
                            </Form.Item>
                          </Col>
                          <Col span={10} key={1}>
                            <Form.Item label="公司名称" name="qryText_group">
                              <Input placeholder="公司名称模糊搜索" />
                            </Form.Item>
                          </Col>

                          <Col span={6} key={5}>
                            <Form.Item
                              label="机构类型"
                              name="txtAgentType_group"
                            >
                              <Select
                                options={agentTypeList}
                                defaultValue={qryType_group}
                              // onChange={this.onAgentType_chg.bind(this)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4} key={4}>
                            <Button type="primary" htmlType="submit">
                              搜索
                            </Button>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {/* <Button
                              type="primary"
                              onClick={() => this.reload()}
                            >
                              刷新
                            </Button> */}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                    {/* 券商研究所 */}
                    {qryType_group == "券商研究所" ? (
                      <Table
                        dataSource={tableData_group}
                        loading={loading}
                        pagination={{
                          position: ["none", "bottomCenter"],
                          onChange: this.pageChange_group.bind(this),
                          total: total_group,
                          pageSize: pageSize_group,
                        }}
                        className="futop"
                        size="middle"
                        rowKey={(record) => record.ID}
                        scroll={{ x: "100%" }}
                        style={{ wordBreak: "break-all" }}
                      >
                        <Column
                          title="序号"
                          align="center"
                          width={60}
                          render={(text, record, index) => {
                            return (
                              <div>
                                {(pageIndex_group - 1) * pageSize_group +
                                  index +
                                  1}
                              </div>
                            )
                          }}
                        />
                        <Column
                          title="地区"
                          align="center"
                          width={70}
                          sorter={(a: any, b: any) => a.City.localeCompare(b.City)}
                          render={(record) => {
                            return (
                              <div>
                                <div>{record.City}</div>
                              </div>
                            )
                          }}
                        />
                        <Column
                          title="公司简称"
                          align="center"
                          width={100}
                          sorter={(a: any, b: any) => a.AbbrName.localeCompare(b.AbbrName)}
                          render={(record) => {
                            return (
                              <div
                                onClick={() =>
                                  this.goGroupDetail(record.CompanyCode)
                                }
                                className="link"
                              >
                                {record.AbbrName}
                              </div>
                            )
                          }}
                        />
                        <Column
                          title="公司全称"
                          align="center"
                          width={150}
                          sorter={(a: any, b: any) => a.CompanyName.localeCompare(b.CompanyName)}
                          render={(record) => {
                            return (
                              <div
                                onClick={() =>
                                  this.goGroupDetail(record.CompanyCode)
                                }
                                className="link"
                              >
                                {record.CompanyName}
                              </div>
                            )
                          }}
                        />
                        <Column
                          title="地址1"
                          align="center"
                          width={120}
                          sorter={(a: any, b: any) => a.Address.localeCompare(b.Address)}
                          render={(record) => {
                            return <div>{record.Address}</div>
                          }}
                        />
                        <Column
                          title="地址2"
                          align="center"
                          width={120}
                          sorter={(a: any, b: any) => a.Address1.localeCompare(b.Address1)}
                          render={(record) => {
                            return <div>{record.Address1}</div>
                          }}
                        />
                        <Column
                          title="地址3"
                          align="center"
                          width={120}
                          sorter={(a: any, b: any) => a.Address2.localeCompare(b.Address2)}
                          render={(record) => {
                            return <div>{record.Address2}</div>
                          }}
                        />
                        {/* <Column
                          title="地址4"
                          align="center"
                          width={120}
                          render={(record) => {
                            return <div>{record.Address3}</div>
                          }}
                        /> */}
                        <Column
                          title="联系电话"
                          align="center"
                          width={90}
                          sorter={(a: any, b: any) => a.Phone.localeCompare(b.Phone)}
                          render={(record) => {
                            return <div>{record.Phone}</div>
                          }}
                        />
                        {/* <Column
                          title="销售电话"
                          align="center"
                          width={90}
                          render={(record) => {
                            return <div>{record.Phone_Sale}</div>
                          }}
                        /> */}
                        <Column
                          title="邮箱"
                          align="center"
                          width={90}
                          sorter={(a: any, b: any) => a.Email.localeCompare(b.Email)}
                          render={(record) => {
                            return <div>{record.Email}</div>
                          }}
                        />
                        {/* <Column
                          title="网站"
                          align="center"
                          width={100}
                          sorter={(a: any, b: any) => a.WebSite.localeCompare(b.WebSite)}
                          render={(record) => {
                            return (
                              <div
                                onClick={() => api.goWebSite(record.WebSite)}
                                className="link"
                              >
                                {record.WebSite}
                              </div>
                            )
                          }}
                        /> */}

                        <Column
                          title="公众号"
                          align="center"
                          width={70}
                          sorter={(a: any, b: any) => a.PublicCode.localeCompare(b.PublicCode)}
                          render={(record) => {
                            return <div>{record.PublicCode}</div>
                          }}
                        />
                      </Table>
                    ) : (
                        <Table
                          dataSource={tableData_group}
                          loading={loading}
                          pagination={{
                            position: ["none", "bottomCenter"],
                            onChange: this.pageChange_group.bind(this),
                            total: total_group,
                            pageSize: pageSize_group,
                          }}
                          className="futop"
                          size="middle"
                          rowKey={(record) => record.ID}
                          //scroll={{ x: "100%" }}
                          style={{ wordBreak: "break-all" }}
                        >
                          <Column
                            title="序号"
                            align="center"
                            width={60}
                            render={(text, record, index) => {
                              return (
                                <div>
                                  {(pageIndex_group - 1) * pageSize_group +
                                    index +
                                    1}
                                </div>
                              )
                            }}
                          />
                          <Column
                            title="地区"
                            align="center"
                            width={60}
                            sorter={(a: any, b: any) => a.City.localeCompare(b.City)}
                            render={(record) => {
                              return (
                                <div>
                                  <div>{record.City}</div>
                                </div>
                              )
                            }}
                          />
                          <Column
                            title="公司简称"
                            align="center"
                            width={90}
                            sorter={(a: any, b: any) => a.AbbrName.localeCompare(b.AbbrName)}
                            render={(record) => {
                              return (
                                <div
                                  onClick={() =>
                                    this.goGroupDetail(record.CompanyCode)
                                  }
                                  className="link"
                                >
                                  {record.AbbrName}
                                </div>
                              )
                            }}
                          />
                          <Column
                            title="公司全称"
                            align="center"
                            width={150}
                            sorter={(a: any, b: any) => a.CompanyName.localeCompare(b.CompanyName)}
                            render={(record) => {
                              return (
                                <div
                                  onClick={() =>
                                    this.goGroupDetail(record.CompanyCode)
                                  }
                                  className="link"
                                >
                                  {record.CompanyName}
                                </div>
                              )
                            }}
                          />
                          <Column
                            title="地址"
                            align="center"
                            width={150}
                            sorter={(a: any, b: any) => a.Address.localeCompare(b.Address)}
                            render={(record) => {
                              return <div>{record.Address}</div>
                            }}
                          />
                          <Column
                            title="联系方式"
                            align="center"
                            width={90}
                            sorter={(a: any, b: any) => a.Phone.localeCompare(b.Phone)}
                            render={(record) => {
                              return <div>{record.Phone}</div>
                            }}
                          />
                          <Column
                            title="邮箱"
                            align="center"
                            width={80}
                            sorter={(a: any, b: any) => a.Email.localeCompare(b.Email)}
                            render={(record) => {
                              return <div>{record.Email}</div>
                            }}
                          />
                          
                          <Column
                            title="公众号"
                            align="center"
                            width={70}
                            sorter={(a: any, b: any) => a.PublicCode.localeCompare(b.PublicCode)}
                            render={(record) => {
                              return <div>{record.PublicCode}</div>
                            }}
                          />
                        </Table>
                      )}
                  </div>
                ) : null}
                {idx === 3 ? (
                  /* 私募奖项 */
                  <div className="goldStockBigData">
                     <div className="changeTab">
                      {this.state.awardTab.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              index === this.state.awardTabIndex
                                ? "active"
                                : null
                            }
                            onClick={() => this.changeAwardTab(index)}
                          >
                            {item}
                          </div>
                        )
                      })}
                    </div>
                    <div className="search">
                      <Form
                        name="basic"
                        onFinish={this.onFormFinishAward.bind(this)}
                        autoComplete="off"
                      >
                        <Row gutter={24}>
                          <Col span={7} key={1}>
                            <Form.Item label="大赛名称" name="qryText">
                              <Input placeholder="大赛名称模糊搜索" />
                            </Form.Item>
                          </Col>
                          <Col span={6} key={2}>
                            <Form.Item label="起止日期" name="txtReqSDate">
                              <RangePicker
                                locale={locale}
                                onChange={this.onDateChange_award.bind(this)}
                                onOk={this.onDateOk_sim}
                              />
                            </Form.Item>
                          </Col>
                          {/* <Col span={5} key={5}>
                            <Form.Item label="奖项类型" name="txtAgentType">
                              <Select options={awardTypeList} />
                            </Form.Item>
                          </Col> */}
                          <Col span={7} key={3}>
                            <Form.Item label="主办方" name="qrySource">
                              <Input placeholder="主办方模糊搜索" />
                            </Form.Item>
                          </Col>
                          <Col span={4} key={4}>
                            <Button type="primary" htmlType="submit">
                              搜索
                            </Button>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                              type="primary"
                              onClick={() => this.reload_award()}
                            >
                              刷新
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                   
                    <Table
                      dataSource={tableData_award}
                      loading={loading}
                      pagination={{
                        position: ["none", "bottomCenter"],
                        onChange: this.pageChange_award.bind(this),
                        total: total_award,
                        pageSize: pageSize,
                      }}
                      className="futop"
                      size="middle"
                      rowKey={(record) => record.ID}
                    >
                      <Column
                        title="大赛全称"
                        width={220}
                        align="center"
                        sorter={(a: any, b: any) => a.FullName.localeCompare(b.FullName)}
                        render={(record) => {
                          return (
                            <div onClick={() => this.showAwardModalOk(record.FullCode)}  className="link">
                            {record.FullName}
                          </div>
                          )
                        }}
                      />
                      {0 === this.state.awardTabIndex ? (
                      <Column
                        title="大赛简称"
                        width={130}
                        align="center"
                        sorter={(a: any, b: any) => a.AbbrName.localeCompare(b.AbbrName)}
                        render={(record) => {
                          return (
                            <div onClick={() => this.showAwardModalOk(record.FullCode)}  className="link">
                            {record.AbbrName}
                          </div>
                          )
                        }}
                      />
                      ):null}
                      <Column
                        title={0 === this.state.awardTabIndex ? "开始时间":"公告日期"}
                        width={110}
                        align="center"
                        sorter={(a, b) => new Date(a.WinnerTime).getTime() - new Date(b.WinnerTime).getTime()}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.WinnerTime}</div>
                            </div>
                          )
                        }}
                      />
                      {0 === this.state.awardTabIndex ? (
                      <Column
                        title="结束时间"
                        width={110}
                        align="center"
                        sorter={(a, b) => new Date(a.EndDate).getTime() - new Date(b.EndDate).getTime()}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.EndDate}</div>
                            </div>
                          )
                        }}
                      />
                      ):null}
                      <Column
                        title="年度"
                        width={90}
                        align="center"
                        sorter={(a, b) => a['Year'] && b['Year'] ? a.Year - Year : false}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.Year}</div>
                            </div>
                          )
                        }}
                      />
                      <Column
                        title="届次"
                        width={100}
                        align="center"
                        sorter={(a: any, b: any) => a.Session.localeCompare(b.Session)}
                        render={(record) => {
                          return (
                            <div>
                                  {record.Session}
                            </div>
                          )
                        }}
                      />
                      <Column
                        title="主办方"
                        width={180}
                        align="center"
                        sorter={(a: any, b: any) => a.Organizer.localeCompare(b.Organizer)}
                        render={(record) => {
                          return (
                            <div
                            onClick={() => this.goSource_award(record.Organizer, "")}
                            className="link"
                          >
                            {record.Organizer}
                          </div>
                          )
                        }}
                      />
                      <Column
                        title="协办单位"
                        width={150}
                        align="center"
                        sorter={(a: any, b: any) => a.CoOrgUnit.localeCompare(b.CoOrgUnit)}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.CoOrgUnit}</div>
                            </div>
                          )
                        }}
                      />
                      <Column
                        title="进度"
                        width={100}
                        align="center"
                        sorter={(a: any, b: any) => a.Schedule.localeCompare(b.Schedule)}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.Schedule}</div>
                            </div>
                          )
                        }}
                      />
                    </Table>
                  </div>
                ) : null}

<Modal title="奖项详情" width="600" visible={isAwardModalOpen} onOk={this.handleAwardModalOk.bind(this)} onCancel={this.handleAwardModalOk.bind(this)}>
<Table
                      dataSource={tableData_award_detail}
                      loading={loading}
                      pagination={{
                        position: ["none", "bottomCenter"],
                      }}
                      className="futop"
                      size="middle"
                      rowKey={(record) => record.ID}
                    >
                      <Column
                        title="公司简称"
                        width={120}
                        align="center"
                        sorter={(a: any, b: any) => a.ComAbbrName.localeCompare(b.ComAbbrName)}
                        render={(record) => {
                          return (
                            <div onClick={() => this.goGroupDetail(record.CompanyCode)}  className="link">
                            {record.ComAbbrName}
                          </div>
                          )
                        }}
                      />

                      <Column
                        title="大赛全称"
                        width={220}
                        align="center"
                        sorter={(a: any, b: any) => a.FullName.localeCompare(b.FullName)}
                        render={(record) => {
                          return (
                            <div>
                            {record.FullName}
                          </div>
                          )
                        }}
                      />
                      {0 === this.state.awardTabIndex ? (
                      <Column
                        title="大赛简称"
                        width={130}
                        align="center"
                        sorter={(a: any, b: any) => a.AbbrName.localeCompare(b.AbbrName)}
                        render={(record) => {
                          return (
                            <div>
                            {record.AbbrName}
                          </div>
                          )
                        }}
                      />
                      ):null}
                      {0 === this.state.awardTabIndex ? (
                      <Column
                        title="排名周期"
                        width={130}
                        align="center"
                        sorter={(a: any, b: any) => a.RankCycle.localeCompare(b.RankCycle)}
                        render={(record) => {
                          return (
                            <div>
                            {record.RankCycle}
                          </div>
                          )
                        }}
                      />):<Column
                      title="年期"
                      width={130}
                      align="center"
                      sorter={(a: any, b: any) => a.Period.localeCompare(b.Period)}
                      render={(record) => {
                        return (
                          <div>
                          {record.Period}
                        </div>
                        )
                      }}
                    />}
                    <Column
                        title="策略类型"
                        width={110}
                        align="center"
                        sorter={(a: any, b: any) => a.PolicyType.localeCompare(b.PolicyType)}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.PolicyType}</div>
                            </div>
                          )
                        }}
                      />
                      <Column
                        title="奖项"
                        width={110}
                        align="center"
                        sorter={(a: any, b: any) => a.Awards.localeCompare(b.Awards)}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.Awards}</div>
                            </div>
                          )
                        }}
                      />
                      {0 === this.state.awardTabIndex ? (
                        <Column
                        title="获奖产品名称"
                        width={150}
                        align="center"
                        sorter={(a: any, b: any) => a.AwardName.localeCompare(b.AwardName)}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.AwardName}</div>
                            </div>
                          )
                        }}
                      />):
                      <Column
                        title="公告日期"
                        width={110}
                        align="center"
                        sorter={(a, b) => new Date(a.PublishDate1).getTime() - new Date(b.PublishDate1).getTime()}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.PublishDate1}</div>
                            </div>
                          )
                        }}
                      />}
                      {0 === this.state.awardTabIndex ? (
                      <Column
                        title="获奖排名"
                        width={110}
                        align="center"
                        sorter={(a, b) => a.AwardsRank - b.AwardsRank}
                        render={(record) => {
                          return (
                            <div>
                              <div>{record.AwardsRank}</div>
                            </div>
                          )
                        }}
                      />
                      ):<Column
                      title="获奖经理人"
                      width={110}
                      align="center"
                      sorter={(a, b) => a.AwardWinManager - b.AwardWinManager}
                      render={(record) => {
                        return (
                          <div>
                            <div>{record.AwardWinManager}</div>
                          </div>
                        )
                      }}
                    />}
                      
                      
                    </Table>
      </Modal>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    )
  }
}

export default Group
