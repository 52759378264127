import React from "react"
import "./style/detail.scss"
import { Spin, Input, Select, Table, Pagination, Tabs, DatePicker } from "antd"
import { Link } from "react-router-dom"
import api from "../../util/api"

const { Column } = Table

const data = [
  {
    id: 1,
    code: 300012,
    name: "泰康生物",
    price: 129.2,
    up: -21,
    yearUp: 21,
  },
]

class GoldStockDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    console.log("aa aaaa")
    this.state = {
      loading: false,
      tableData: [],
    }
  }

  componentDidMount() {
    let composeId = api.decode(this.props.match.params.id)
    console.log("composeId==", composeId)
    api.getGoldStockDetail(composeId).then((res) => {
      console.log("detail res==", res)
      let tableData = res && res.Table ? res.Table : []
      this.setState({
        tableData: tableData,
      })
    })
  }

  render() {
    const { loading, tableData } = this.state

    return (
      <div className="goldStockDetailsContainer">
        <Spin spinning={loading}>
          <div className="content">
            <div className="content_bg">
              <div className="content_box">
                {/* 面包屑 */}
                <div className="bread">
                  当前位置:
                  <Link className="link" to="/goldStock">
                    机构金股
                  </Link>
                  &gt;&nbsp;&nbsp; 券商金股
                </div>
                {/* 表格 */}
                <div className="tableBox">
                  <Table
                    dataSource={tableData}
                    pagination={{
                      position: ["none", "bottomCenter"],
                      onChange: this.pageChange,
                    }}
                    rowKey={(record) => record.ID}
                  >
                    <Column
                      title="编号"
                      dataIndex="ID"
                      render={(text, record, index) => {
                        return <div>{index + 1}</div>
                      }}
                    />
                    <Column
                      title="代码"
                      dataIndex="StockCode"
                      render={(StockCode) => {
                        return <div>{StockCode}</div>
                      }}
                    />
                    <Column
                      title="股票名称"
                      dataIndex="StockName"
                      render={(StockName) => {
                        return <div>{StockName}</div>
                      }}
                    />
                    <Column
                      title="本月收益"
                      dataIndex="ProfitByMonth"
                      render={(ProfitByMonth) => {
                        return (
                          <>
                            {ProfitByMonth > 0 ? (
                              <div className="redFont">{ProfitByMonth}</div>
                            ) : null}
                            {ProfitByMonth < 0 ? (
                              <div className="greenFont">{ProfitByMonth}</div>
                            ) : null}
                            {ProfitByMonth == 0 ? (
                              <div className="greyFont">{ProfitByMonth}%</div>
                            ) : null}
                          </>
                        )
                      }}
                    />
                    <Column
                      title="今年收益"
                      dataIndex="ProfitByYear"
                      render={(ProfitByYear) => {
                        return (
                          <>
                            {ProfitByYear > 0 ? (
                              <div className="redFont">+{ProfitByYear}%</div>
                            ) : null}
                            {ProfitByYear < 0 ? (
                              <div className="greenFont">{ProfitByYear}%</div>
                            ) : null}
                            {ProfitByYear == 0 ? (
                              <div className="greyFont">{ProfitByYear}%</div>
                            ) : null}
                          </>
                        )
                      }}
                    />
                    <Column
                      title="累计收益"
                      dataIndex="ProfitByAccu"
                      key="ProfitByAccu"
                      render={(ProfitByAccu) => {
                        return (
                          <>
                            {ProfitByAccu > 0 ? (
                              <div className="redFont">+{ProfitByAccu}%</div>
                            ) : null}
                            {ProfitByAccu < 0 ? (
                              <div className="greenFont">{ProfitByAccu}%</div>
                            ) : null}
                            {ProfitByAccu == 0 ? (
                              <div className="greyFont">{ProfitByAccu}%</div>
                            ) : null}
                          </>
                        )
                      }}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    )
  }

  pageChange(page, pageSize) {
    console.log("page", page)
    console.log("pageSize", pageSize)
  }
}

export default GoldStockDetails
