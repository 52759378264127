import React from "react"
import { NavLink, Link } from "react-router-dom"
import styled from "styled-components"
import {
  message,
  Spin,
  Radio,
  Pagination,
  DatePicker,
  Space,
  Form,
  Input,
  Col,
  Row,
  Switch,
  Select,
  Button,
  Descriptions,
  Menu,
} from "antd"
import {
  CaretUpOutlined,
  CaretDownOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons"
import "moment/locale/zh-cn"
import locale from "antd/es/date-picker/locale/zh_CN"
import api from "../../../util/api"
import { thisExpression, throwStatement } from "@babel/types"
import moment from "moment"

const IndexCss = styled.div`
  .btn { 
    margin-left:40px;
  }
`

class MyInfo extends React.PureComponent {
  constructor(props) {
    super(props)
  }
  componentDidMount() {}

  
  render() {
    const { myInfo } = this.props
    return (
      <IndexCss>
      <Descriptions
        title=""
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
        style={{ margin: "40px" }}
        className="table"
      >
        <Descriptions.Item label="用户名">{myInfo.USERNAME}</Descriptions.Item>
        <Descriptions.Item label="姓名">{myInfo.FNAME}</Descriptions.Item>
        <Descriptions.Item label="机构">{myInfo.CORP}</Descriptions.Item>
        <Descriptions.Item label="职位">{myInfo.POSITION}</Descriptions.Item>
        <Descriptions.Item label="邮箱">{myInfo.EMAIL}</Descriptions.Item>
        <Descriptions.Item label="手机">{myInfo.MOBILE}</Descriptions.Item>
        <Descriptions.Item label="QQ">{myInfo.QQ}</Descriptions.Item>
        <Descriptions.Item label="微信">{myInfo.WEIXIN}</Descriptions.Item>
       
        <Descriptions.Item label="状态">{myInfo.STATENAME}</Descriptions.Item>
        <Descriptions.Item label="VIP到期时间">
          {myInfo.VIPENDTIME}
        </Descriptions.Item>
      </Descriptions>
      
      </IndexCss>
    )
  }
}
export default MyInfo
