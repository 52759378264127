import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom'

class GoldStockBase extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <Router>
        <div>
          <Switch>
            {this.props.routes.map((item) => {
              return (
                <Route
                  exact={item.exact}
                  path={item.path}
                  key={item.path}
                  component={item.component}
                />
              )
            })}
          </Switch>
        </div>
      </Router>
    )
  }
}

export default GoldStockBase
